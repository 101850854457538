import { AppLang } from '@/lang/_template.type';

const kr: AppLang = {
	app_name: 'Pixcap',
	promo_banner: {
		title: '프로모션 코드 <span class="text-weight-600">{promo_code}!</span>로 Pro & Elite 연간 플랜 {discount}% 할인 혜택을 누리세요',
		cta_text: '{discount}% 할인 받기',
	},
	navbar: {
		search_placeholder: '검색...',
		search_dropdown: {
			recent_search: '최근 검색',
			popular_search: '인기 검색',
			suggested_search: '추천 검색',
		},
		account: '계정',
		navigation_items: {
			browse: '둘러보기',
			asset: '3D 에셋',
			asset_dropdown: {
				icons_title: '3D 아이콘',
				icons_description: '15,000개 이상의 무료 및 프리미엄 3D 아이콘 & 일러스트레이션',
				characters_title: '3D 캐릭터',
				characters_description: '1,000개 이상의 무료 및 프리미엄 3D 캐릭터',
				mockups_title: '목업',
				mockups_description: '500개 이상의 무료 및 프리미엄 목업과 브랜딩 목업 키트',
			},
			tools: '도구',
			tools_dropdown: {
				prefix: '최고의 AI 도구',
				ai_3d_model: 'AI 3D 모델 생성기',
				ai_character: 'AI 캐릭터 생성기',
				ai_girl: 'AI 여성 생성기',
				ai_background: 'AI 배경 생성기',
				ai_filter: 'AI 필터',
				ai_icon: 'AI 아이콘 생성기',
				icon_maker: '3D 아이콘 메이커',
				animated_icon: '애니메이션 아이콘',
				character_creator: '3D 캐릭터 크리에이터',
			},
			pricing: '가격',
		},

		language_switcher: {
			tooltip: '언어',
		},

		my_projects: '내 프로젝트',

		login: '로그인',
		notification_dropdown: {
			notifications: '알림',
			empty: '알림이 없습니다',
		},
		download_dropdown: {
			full_download_history: '전체 다운로드 기록',
			tooltip: {
				download_history: '다운로드 기록',
				download_completed: '다운로드 완료',
			},
			show_all_downloads: '모든 다운로드 보기',
			empty: '여기는 비어 있습니다. \n 마법이 일어나는 곳을 보려면 \n (고품질) 다운로드를 해보세요.',
			upgrade: {
				enjoy: 'Pro/Elite 사용자는 <span class="text-color-pro">3배 더 빠른 렌더링</span>을 즐길 수 있다는 사실을 알고 계셨나요?',
				text: '지금 업그레이드하고 고품질 다운로드 속도 향상을 경험하세요!',
				upgrade_now: '지금 업그레이드',
			},
			cancel_download_modal: {
				cancel_download: '고품질 다운로드 취소',
				text: '이 동영상에 사용된 잔액을 잃게 됩니다. 계속 진행하시겠습니까?',
				text_gif: '이 GIF에 사용된 잔액을 잃게 됩니다. 계속 진행하시겠습니까?',
				confirm: '확인',
			},
		},
		user_dropdown: {
			projects: '프로젝트',
			downloads: '다운로드',
			account_settings: '계정 설정',
			invite_friends: '친구 초대',
			contact_us: '문의하기',
			shared_project: '공유된 프로젝트',
			contributor_files: '기여자 파일',
			sign_out: '로그아웃',
		},
		login_form: {
			title: '15,000개 이상의 커스터마이징 가능한 3D 에셋 잠금 해제',
			description: '고품질 3D 애니메이션 아이콘, 3D 캐릭터 및 목업.',
			google_login: 'Google로 계속하기',
			create_account: '계정 만들기',
			create_account_heading: '다음으로 Pixcap 계정을 만들고 있습니다',
			accept_term: '계속 진행함으로써 귀하는 당사의',
			next: '다음',
			verify: '확인',
			back: '뒤로',
			email_login: {
				email: '이메일 입력',
				password: '비밀번호 입력',
				confirm_password: '새 비밀번호 확인',
				resend: '재전송',
				email_login: '이메일로 계속하기',
				enter_email: '이메일 주소 입력',
				enter_verification_code: '인증 코드 입력',
				verification_sent: '다음 주소로 인증 코드를 보냈습니다',
				enter_email_message: '로그인하거나 계정이 없는 경우<br />새 계정을 만들어 드립니다.',
				verify_email: '이메일 확인',
				check_email: '<span class="text-color-primary-5">{{ email }}</span>로 전송된 이메일에서 이메일 확인 코드를 확인하세요.',
				invalid_code: '입력한 코드가 유효하지 않습니다. 다시 시도하세요',
				did_not_received: '이메일을 받지 못하셨나요?',
				error_email: '유효한 이메일을 입력해 주세요.',
				error_E02_02_001: '이메일 또는 비밀번호가 올바르지 않습니다.',
				error_E02_02_002: '이 이메일은 이미 사용 중입니다',
				error_E02_02_003: '문제가 발생했습니다. 다시 시도해 주세요',
				error_default: '로그인에 실패했습니다. 다시 시도해 주세요',
			},
		},
		forgot_pw_form: {
			forgot_pw: '비밀번호를 잊으셨나요? ',
			sub: '비밀번호를 재설정하려면 이메일 주소를 입력해 주세요.',
			check_your_email: '비밀번호 재설정 코드가 포함된 이메일을 <strong>{email}</strong>로 보냈습니다.',
			sub_text: '비밀번호 재설정 링크가 포함된 이메일을 보냈습니다.',
			did_not_received: '이메일을 받지 못하셨나요?',
			enter_reset_code: '재설정 코드 입력',
			reset_password: '비밀번호 재설정',
			error_email: '이메일이 유효하지 않습니다',
			change_password: '비밀번호 변경',
			continue: '계속',
		},
		contact_us_form: {
			contact_us: '문의하기',
			name: '이름',
			enter_your_name: '이름을 입력하세요',
			email: '이메일',
			enter_your_email: '이메일을 입력하세요',
			message: '메시지',
			write_message: '여기에 메시지를 작성하세요',
			send: '메시지 보내기',
			error_name: '**이름은 비워둘 수 없습니다',
			error_email: '**이메일이 유효하지 않습니다',
		},
	},
	sidebar: {
		home: '홈',
		library: '라이브러리',
		my_projects: '내 프로젝트',
		my_assets: '내 에셋',
		pricing: '가격',
		unlock_all_features: '모든 기능 잠금 해제',
		upgrade_plan: '플랜 업그레이드',
	},
	breadcrumb: {
		home: '홈',
		mockups: '목업',
		brandkit_mockups: '브랜드 키트 목업',
		icon_packs: '3D 아이콘 팩',
		illustrations: '일러스트레이션',
		designs: '디자인',
	},
	footer: {
		title: '더 많은 3D 콘텐츠 탐색',
		searched_title: '관련 검색 둘러보기',
		seo_section: {
			sort_by: {
				latest: '최신순',
				trending: '트렌딩',
			},
		},
		footer_section: {
			explore: {
				title: '탐색',
			},
			features: {
				title: '기능',
				animated_mockup: '애니메이션 목업',
				ai_style_transfer: 'AI 스타일 변환',
				character_library: '캐릭터 라이브러리',
				material_library: '재질 라이브러리',
			},
			company: {
				title: '회사',
				pricing: '가격',
				enterprise: '기업',
				privacy_policy: '개인정보 처리방침',
				terms_of_service: '이용 약관',
				contact_us: '문의하기',
			},
			resources: {
				title: '리소스',
				glossary: '용어집',
				contributor: '기여자 되기',
			},
			tools: {
				title: '도구',
				ai_3d_model: 'AI 3D 모델 생성기',
				ai_character: 'AI 캐릭터 생성기',
				ai_girl: 'AI 여성 생성기',
				ai_background: 'AI 배경 생성기',
			},
			create: {
				title: '만들기',
				mug_design: '머그컵 디자인',
				sticker_design: '스티커 디자인',
				friendsgiving_invitation: '프렌즈기빙 초대장',
				poster_mockup: '포스터 목업',
				iphone_mockup: '아이폰 목업',
			},
			blogs: {
				title: '블로그',
				all_blogs: '모든 블로그',
			},
			follow_us: {
				title: '팔로우하기',
			},
		},
	},

	page: {
		home: {
			title: '편집 가능한 3D 에셋을 위한 <br> <span class="text-weight-600 text-color-primary-5">유일한 마켓플레이스</span>',
			description: '웹 브라우저에서 고품질 3D 아이콘, 3D 캐릭터, 목업 및 3D 일러스트레이션을 편집하세요.',
			partners: {
				title: '다음 팀들이 사용하고 있습니다',
			},
			interactive_banner: {
				title: '독특한 3D 요소를 쉽게 만들어보세요',
				description: '간단한 에디터를 사용하여 모든 3D 에셋의 색상, 재질, 애니메이션 스타일을 조정해보세요.',
				default: '기본',
				color: '색상',
			},
			animated_icon_pack: '애니메이션 3D 아이콘',
			animated_icon_pack_description: '색상 변경, 카메라 회전, 재질 조정 등으로 애니메이션 3D 아이콘을 커스터마이징하세요.',
			icon_pack: '3D 아이콘',
			icon_pack_description: '색상 변경, 카메라 회전, 재질 조정 등으로 3D 아이콘을 커스터마이징하세요.',
			characters_pack_description: '포즈와 색상을 변경하여 독특한 3D 캐릭터와 3D 일러스트레이션을 만들어보세요.',
			device_mockup_pack_description:
				'라벨을 업로드하고 독특한 UX/UI 목업을 만들어보세요. 색상을 커스터마이징하고 MP4, PNG 또는 GIF로 다운로드하세요.',
			branding_mockup_pack_description:
				'라벨을 업로드하고 독특한 브랜딩 목업을 만들어보세요. 색상을 커스터마이징하고 MP4, PNG 또는 GIF로 다운로드하세요.',
			latest_icon_pack: '최신 3D 아이콘',
			characters_pack: '3D 캐릭터',
			device_mockup_pack: '디바이스 목업',
			branding_mockup_pack: '브랜딩 목업',
			advertise: {
				title: '프로젝트를 위한 최고의 3D 디자인 도구',
				description: '웹 디자인, 모바일 앱, 마케팅 등을 위해 3D 모델을 편집하고 사용해보세요',
				first_card: {
					title: '웹사이트 & 모바일 앱',
					description: '3D 요소를 사용하여 프로젝트의 디자인을 향상시키세요. 사용자의 주목을 끌고 경쟁사와 차별화된 웹사이트와 앱을 만들어보세요.',
				},
				second_card: {
					title: '애니메이션 목업',
					description: '어떤 애니메이션 목업에도 디자인을 업로드하고, 배경을 원하는 대로 편집한 후 5분 이내에 4K로 다운로드하세요.',
				},
				third_card: {
					title: '프레젠테이션',
					description:
						'프레젠테이션을 위해 3D 캐릭터와 3D 아이콘을 활용하세요. 비즈니스 피치나 교육용 프레젠테이션을 더욱 매력적이고 역동적으로 만들어보세요.',
				},
			},
			compatible: {
				title: '당신이 선호하는 소프트웨어와 호환됩니다',
				description: 'Pixcap 콘텐츠를 GLB, MP4, GIF, PNG 등 모든 파일 형식으로 다운로드하여 모든 선호하는 소프트웨어에서 사용해보세요.',
			},

			banner: {
				title_desktop: `3D 콘텐츠를 커스터마이징하며 <br> 시작해보세요!`,
				title_mobile: `3D 콘텐츠 편집을 <br> 시작해보세요!`,
				button_text: '지금 탐색하기',
			},

			header1: '모든 3D 굿즈는 커스터마이징 가능합니다 - 지금 시도해보세요!',
			trending_icons: '트렌딩 아이콘',
			essential_icon: '필수 아이콘 팩',
			essential_illustrations: '필수 일러스트레이션 키트',
			essential_mockups: '현실적인 디바이스 & 브랜딩 목업',

			header2: '더 많은 편집 가능한 3D 크리에이티브 둘러보기',
			fintech_illustrations: '핀테크 일러스트레이션',
			ecommerce_illustrations: '이커머스 일러스트레이션',
			healthcare_illustrations: '헬스케어 일러스트레이션',
			browse_all_illustrations: '모든 일러스트레이션 둘러보기',
			business_characters: '비즈니스 캐릭터',
			casual_characters: '캐주얼 캐릭터',
			profession_characters: '전문직 캐릭터',
			browse_all_characters: '모든 캐릭터 둘러보기',
			laptop_mockups: '노트북 목업',
			smartphone_mockups: '스마트폰 목업',
			branding_mockups: '브랜딩 목업',
			browse_all_mockups: '모든 목업 둘러보기',
		},
		library: {
			banner: {
				title_icons: '3D {animate} {tag} 아이콘 팩 개인화 & 편집',
				title_characters: '3D {tag} 캐릭터 편집 & 포즈 설정',
				title_mockups: '디바이스 & 브랜딩 {tag} 목업에 디자인 적용',
				description_icons:
					'에디터를 사용하여 3D {animate} 아이콘의 색상, 재질, 애니메이션 스타일을 조정해보세요. Figma, Sketch, Adobe 등에서 사용할 수 있도록 무료 3D 아이콘을 PNG, GLB, GIF, MP4 형식으로 다운로드하세요.',
				description_characters:
					'3D 모델링 전문 지식 없이도 3D 캐릭터의 포즈를 설정하고, 색상 등을 수정할 수 있는 유일한 3D 포즈 설정 도구를 만나보세요.',
				description_mockups:
					'이제 목업을 완전히 커스터마이징할 수 있습니다 - 어떤 애니메이션 목업에도 라벨을 업로드하고 5분 이내에 4K로 다운로드하세요.',
			},
			title: {
				prefix_customize: '커스터마이즈',
				prefix_free: '무료',
				prefix_premium: '프리미엄',
				prefix_free_and_premium: '무료 & 프리미엄',
				icons: '3D 아이콘 팩',
				characters: '3D 캐릭터',
				mockups: '목업',
			},
			description: {
				icons:
					'웹사이트, 앱, 그래픽 디자인 및 마케팅을 위한 {count} {premium} {animated} {tag} 고품질 아이콘 세트 중에서 선택하세요. 디자인 프로젝트를 위해 아이콘을 커스터마이징하고 PNG, GLB, GIF, MP4 형식으로 다운로드하세요.',
				characters:
					'{premium} {tag} 캐릭터 일러스트레이션으로 사용자 참여와 스토리텔링을 향상시키세요. 소셜 미디어 게시물, 프레젠테이션, 설명 비디오, 제품 데모 등에서 사용할 수 있도록 커스터마이징하세요.',
				mockups:
					'{premium} {animated} {tag} 현실적인 목업으로 디자인과 프레젠테이션을 향상시키세요. 쉽게 커스터마이징할 수 있어 제품, 브랜딩, 포장 등을 선보이는 데 완벽합니다.',
			},
			header: '완전히 커스터마이징 가능한 3D 콘텐츠를 탐색해보세요!',
			cards: {
				icons: {
					icons: '아이콘',
					browse_all: '모든 3D 아이콘 둘러보기',
				},
				characters: {
					characters: '캐릭터',
					browse_all: '모든 3D 캐릭터 둘러보기',
				},
				illustrations: {
					illustrations: '일러스트레이션',
					browse_all: '모든 3D 일러스트레이션 둘러보기',
				},
				mockups: {
					mockups: '목업',
					branding_kits: '브랜딩 목업 키트',
					browse_all: '모든 현실적인 3D 목업 둘러보기',
				},
				designs: {
					designs: '디자인',
					browse_all: '모든 3D 디자인 둘러보기',
				},
			},
		},
		pack_details: {
			mockup_descriptions:
				'전문적인 브랜딩 목업 템플릿 라이브러리로 즉각적인 영감을 얻고 브랜드의 디자인을 선보이세요. 세련된 명함, 눈길을 끄는 패키징부터 멋진 소셜 미디어 에셋과 웹사이트 목업까지, 현실적인 설정에서 브랜드 아이덴티티를 시각화하는 데 필요한 모든 것을 제공합니다.',
			features: {
				premium_asset: '모든 프리미엄 에셋',
				exclusive_materials: '독점 재질 & 프리셋',
				exclusive_poses: '독점 캐릭터 포즈',
				commercial_license: '상업용 라이선스',
				export: '3D 파일 내보내기',
				edit: '색상, 재질 & 프리셋 편집',
				downloads: '고품질 다운로드',
				elite_unlimited_images: '무제한 고품질 이미지',
				elite_video_per_month: '월 30회 고품질 비디오',
			},
			get_all_access: '모든 액세스 얻기',

			info: {
				info: '정보',
				number_of_items: '아이템 수',
				file_type: '파일 유형',
				compatible: '호환 가능',
				release: '출시',
				author: '작성자',
			},
			preview: {
				preview: '미리보기',
				default: {
					render: '브라우저의 기본 엔진을 사용하여 렌더링',
					shadow: '강한 그림자',
				},
				hq: {
					render: '최적화된 서버 엔진을 사용하여 렌더링',
					shadow: '레이 트레이싱, 현실적인 그림자 & 조명',
				},
			},
			mockup_pack_description:
				'{pack_name} 목업 팩은 전문적인 방식으로 디자인을 선보이기 위한 고품질 {pack_name} 목업 모음입니다. <br> {pack_name} 목업 팩을 사용하면 쉽게 디자인을 커스터마이징하고 선보일 수 있습니다. 디자인을 업로드하고 객체와 배경을 편집하여 현실적인 목업을 만들어보세요. <br> 모든 {pack_name} 목업은 다양한 플랫폼에서 사용할 수 있도록 PNG, GIF, MP4 형식으로 제공됩니다.',
			pack_description:
				'{pack_name}은 UX/UI, 그래픽 디자인 및 마케팅에 완벽한 3D 아이콘과 애니메이션 아이콘 세트입니다. <br> {pack_name}은 모든 요구 사항에 맞는 다양한 아이콘을 포함하고 있으며, 통일된 모습을 위해 일관된 색상 구성을 유지합니다. <br> 모든 {pack_name} 아이콘은 다양한 플랫폼에서 사용할 수 있도록 PNG, GLB, GIF, MP4 형식으로 제공됩니다.',
			character_pack_description:
				'{pack_name} 캐릭터 팩은 웹사이트, 애니메이션, 그래픽 디자인, 마케팅 및 프레젠테이션에 완벽한 3D 캐릭터와 애니메이션 캐릭터 세트입니다. <br> 이 팩은 각 캐릭터의 피부톤, 머리 색상, 3D 포즈 등을 변경할 수 있는 커스터마이징 옵션을 제공합니다. <br> 모든 {pack_name} 캐릭터는 다양한 플랫폼에서 사용할 수 있도록 PNG, GLB, GIF, MP4 형식으로 제공됩니다.',
			browse_more: '더 많은 편집 가능한 3D 크리에이티브 둘러보기',
			related_to_this_pack: '이 팩과 관련된 항목',
		},
		item_details: {
			features: {
				edit: '색상, 재질, 조명 등 편집',
				edit_character: '포즈, 색상, 재질, 조명 등 편집',
				edit_mockup: '이미지 교체, 색상, 재질, 조명 등 편집',
				downloads: '고품질 다운로드',
				export: '3D 파일 내보내기',
				ai: 'AI 스타일 변환 시도',

				realistic: '현실적인 3D 목업',
				editable: '편집 가능한 디자인 및 배경',
				easy: '사용하기 쉬운 도구',
				high_quality: '고품질 내보내기',
				free: '상업적 사용 무료',
				customize: '커스터마이징 가능한 모양, 색상 & 재질',
				high_resolution: '고해상도 3D 디자인',

				file_types: 'PNG, GLB 파일 유형',
				free_license: '상업 및 개인 프로젝트를 위한 무료 라이선스',
				ready_made: '바로 사용 가능하고 커스터마이징 가능한 템플릿',
				easy_switch: '다양한 크기로 쉽게 전환',
				file_types_2: 'PNG 파일 유형',
				item_description:
					'{item_name}은 웹, 앱, UX/UI, 그래픽 디자인 및 마케팅을 위한 완벽한 아이콘입니다. <br> {item_name}을 사용하면 색상, 재질 및 스타일을 변경하여 디자인 프로젝트에 맞게 완전히 커스터마이징할 수 있습니다. <br> {item_name}을 PNG, GLB, GIF, MP4로 다운로드하여 Figma, Blender, Adobe 등에서 사용하세요.',
				character_description:
					'{item_name}은 웹사이트, 애니메이션, 그래픽 디자인, 마케팅 및 프레젠테이션을 위한 완벽한 캐릭터입니다.<br> {item_name}을 사용하면 피부톤, 머리 색상, 3D 포즈 등을 변경하여 완전히 커스터마이징할 수 있습니다. <br> PNG, GLB 파일 형식으로 제공됩니다.',
				mockup_description:
					'이 {item_name} 목업은 브랜딩, 로고, 웹사이트 레이아웃 등을 표시하는 데 완벽합니다. <br> 객체와 배경을 쉽게 커스터마이징하고 자신만의 디자인 요소를 추가하여 진정으로 독특하게 만들 수 있습니다. <br> {item_name} 목업은 다양한 플랫폼에서 쉽게 편집하고 사용할 수 있도록 PNG, GIF, MP4 등 여러 파일 형식으로 제공됩니다.',
			},

			open_in_mnm: 'Mix-n-match에서 열기',
			edit_this_asset: '이 에셋 편집하기',

			more_from_this_pack: '이 팩의 다른 항목',
			people_also_downloaded: '다른 사람들이 다운로드한 항목',
			text_3d_graphic: '{item_name} 3D 그래픽',
			text_3d_illustration: '{item_name} 3D 일러스트레이션',

			more_from_this_bundle: '이 번들의 템플릿',
			related: '관련 {resouce_type}',
		},
		search: {
			explore: '탐색',
			prefix_free: '무료',
			prefix_premium: '프리미엄',
			prefix_free_and_premium: '무료 & 프리미엄',
			icon_packs: '아이콘 팩',
			character_packs: '캐릭터 팩',
			mockup_packs: '목업 팩',
			asset_packs: '에셋 팩',
			icon: '아이콘',
			icons: '아이콘',
			character: '캐릭터',
			characters: '캐릭터',
			mockup: '목업',
			mockups: '목업',
			asset: '에셋',
			assets: '에셋',

			description: {
				suffix_animated: 'PNG, GLB, GIF, MP4 파일 형식으로 제공됩니다.',
				suffix_pack: '사용자의 주목을 끌기 위해 애니메이션 {search} {type} 팩을 사용해보세요.',
				prefix_pack: '커스터마이징하고 다운로드하세요',
				suffix_icon_packs: '다음 디자인 프로젝트를 위한 팩.',
				suffix_character_packs: '웹, 앱, UX/UI, 그래픽 디자인 및 마케팅을 위한 팩.',
				suffix_mockup_packs: '프레젠테이션을 위한 팩.',
				suffix_asset_packs: '다음 디자인 프로젝트를 위한 팩.',
				suffix_item: '사용자의 주목을 끌기 위해 애니메이션 {search} {type}을(를) 사용해보세요.',
				prefix_item: '커스터마이징하고 다운로드하세요',
				suffix_icon_items: '다음 디자인 프로젝트를 위한 아이콘, 일러스트레이션, 심볼 및 로고.',
				suffix_character_items: '웹, 앱, UX/UI, 그래픽 디자인 및 마케팅을 위한 항목.',
				suffix_mockup_items: '다음 디자인 프로젝트를 위한 항목.',
				suffix_asset_items: '다음 디자인 프로젝트를 위한 항목.',
			},

			elements: '요소',
			packs: '팩',
			illustrations: '일러스트레이션',
			designs: '디자인',
			Elements: '요소',
			Packs: '팩',
			Illustrations: '일러스트레이션',
			Mockups: '목업',
			Designs: '디자인',
			empty: '걱정하지 마세요! 다른 에셋 유형을 탐색하거나,',
			request_new: '새로운 요청하기',
			cant_find: '찾을 수 없습니다',
			browse_relevants: '관련 검색 둘러보기',
		},
		my_projects: {
			title: '프로젝트',
			description: '모든 프로젝트를 한 곳에서 쉽게 커스터마이징하고 관리하세요.',
			header: '내 프로젝트',
			search_placeholder: '파일 검색',
			new_design: '새 디자인',
			default: '기본',
			advanced_editor: '고급 에디터',
			all: '애니메이션 + 정적',
			design: '정적',
			video: '애니메이션',
			edited: '편집됨',
			duplicate_loading: '프로젝트 복제 중....',
			sort: {
				sort_by: '정렬 기준',
				recent: '최신순',
				alphabetical: '알파벳순',
				oldest: '오래된순',
			},

			menu: {
				open_in_advanced_editor: '고급 에디터에서 열기',
				duplicate: '복제',
				duplicate_submission: '제출용으로 복제',
				rename: '이름 변경',
				delete: '삭제',
			},

			empty_state: {
				title: '프로젝트를 채워보세요!',
				description: '이 페이지는 지금 비어 있습니다. 3D 에셋을 커스터마이징할 시간입니다.',
			},
		},
		my_assets: {
			title: '다운로드',
			description: '다운로드한 모든 에셋을 여기서 확인하세요. 언제든지 에셋을 다시 다운로드할 수 있습니다.',
			header: '내 에셋',
			search_placeholder: '파일 검색',
			sort: {
				sort_by: '정렬 기준',
				oldest: '오래된순',
				newest: '최신순',
				alphabet_az: '알파벳순 (A-Z)',
			},
			type: {
				all: '모든 유형',
				default_render: '기본 렌더링',
				high_quality: '고품질',
				ai_generated: 'AI 생성',
			},

			downloads: {
				downloads: '다운로드',
				file: '파일',
				download_status: '다운로드 상태',
				download_date: '다운로드 날짜',

				empty_state: {
					title: '다운로드를 채워보세요!',
					description: '이 페이지는 지금 비어 있습니다. 멋진 에셋을 다운로드할 시간입니다.',

					heading_1: '죄송합니다, 결과를 찾을 수 없습니다',
					heading_2: '죄송합니다, "{search}"에 대한 일치 항목을 찾을 수 없습니다',
					text: '다운로드 페이지가 조금 외롭네요. 멋진 항목을 내보내기 시작할 때입니다.',
					text_search: '검색어나 필터를 조정하여 원하는 것을 찾아보세요.',
					button: '더 탐색하기',
				},
			},
			purchases: {
				purchases: '구매',
				file: '파일',
				purchase_date: '구매 날짜',
				open_file: '파일 열기',
				empty_state: {
					title: '구매 내역이 비어 있습니다.',
					text: '이 공간을 구매한 항목으로 채워보세요.',
					heading_1: '죄송합니다, 결과를 찾을 수 없습니다',
					heading_2: '죄송합니다, "{search}"에 대한 일치 항목을 찾을 수 없습니다',
					text_search: '검색어나 필터를 조정하여 원하는 것을 찾아보세요.',
					button: '더 탐색하기',
				},
				order_no: '주문 번호',
			},
		},
		upgrade_plan: {
			discount_text: '{discount_program} 혜택을 누리세요: 플랜 {discount}% 할인!',
			end_in: '한정 시간 제안이 다음 시간 후 종료됩니다',
			header: 'Pixcap으로 3D의 <br> 모든 힘을 잠금 해제하세요',
			is_pro: '소중한 구독자가 되어 주셔서 감사합니다! 다음 갱신 시 새로운 요금이 적용될 때까지 기존의 낮은 가격을 즐기실 수 있습니다.',
			monthly: '월간',
			quarterly: '3개월',
			yearly: '연간',
			save_up: '최대 33% 절약',
			per_month: '/월',
			recommended: '추천',
			current_short: '현재',
			current_plan: '현재 플랜',
			contact_sales: '영업팀 문의',
			get_plan: '{plan} 얻기',
			bill_annually: '연간 {amount} 청구',
			bill_quarterly: '3개월 {amount} 청구',
			free: {
				free: '무료',
				text: '텍스트',
				forever: '영원히',
			},
			pro: {
				pro: '프로',
				text: '더 전문적인 프로젝트를 위해 프리미엄 기능을 잠금 해제하세요.',
			},
			elite: {
				elite: '엘리트',
				text: '향상된 기능으로 대규모 프로젝트에 적합합니다.',
			},
			enterprise: {
				enterprise: '엔터프라이즈',
				text: '중요한 프로젝트를 위한 고급 솔루션과 맞춤형 지원.',
				custom: '맞춤',
			},
			benefits: {
				limited_free_assets: '제한된 무료 에셋',
				images_per_month: '월 {number}개의 고품질 이미지',
				videos_per_month: '월 {number}개의 고품질 비디오',
				exclusive_character_poses: '독점 캐릭터 포즈',
				exclusive_materials_presets: '독점 재질 & 프리셋',
				commercial_license: '상업용 라이선스',
				all_premiums: '모든 프리미엄 에셋',
				export_3d_files: '3D 파일 내보내기',
				unlimited_images: '무제한 고품질 이미지',

				upto_10_projects: '최대 10개 프로젝트',
				monthly_credits_30: '월 30 크레딧 (슈퍼 다운로드 & Pixcap AI)',
				commercial_license_with_watermark: '상업용 라이선스 (워터마크 포함)',

				upto_50_projects: '최대 50개 프로젝트',
				monthly_credits_600: '월 600 크레딧 (슈퍼 다운로드 & Pixcap AI)',
				super_download: '3배 빠른 슈퍼 다운로드 (이미지)',

				unlimited_projects: '무제한 프로젝트',
				monthly_credits_2000: '월 2000 크레딧 (슈퍼 다운로드 & Pixcap AI)',
				dedicated_support: '전담 지원',
				everything_in_pro: 'Pro의 모든 기능',
				modeling_services: '3D 모델링 서비스',
				unlimited_credits: '무제한 크레딧',
				custom_animations: '맞춤 애니메이션',
				custom_mockups: '맞춤 목업',
				everything_in_elite: 'Elite의 모든 기능',
			},
			vat_included: '부가가치세(VAT) 포함',
			credit_cards: '신용 및 직불 카드 허용',
			cancel_anytime: '언제든지 취소 가능',
			trusted_by: '신뢰받는 기업',
			show_full: '전체 보기',
			hide: '숨기기',
			limit_per_day: '일 {number}개',
			limit_per_month: '월 {number}개',
			unlimited: '무제한',
			limited: '제한',
			medium: '중간',
			fast: '빠름 (3배)',
			customization: {
				customization: '커스터마이제이션',
				all_premiums: '모든 프리미엄 3D 에셋 (아이콘, 캐릭터 & 목업)',
				all_character_poses: '모든 캐릭터 포즈',
				all_materials_presets: '모든 재질 & 프리셋',
			},
			exports: {
				exports: '내보내기',
				tooltip:
					'이미지/비디오를 표준 또는 고품질로 내보낼 수 있습니다: <br> &#x2022; 표준: 기본 품질 렌더링. <br> &#x2022; 고품질: 더 상세하고 전문적인 이미지/비디오를 위한 향상된 레이 트레이싱, 그림자 및 선명도.',
				standard: '기본 이미지 & 비디오',
				hq_images: '고품질(HQ) 이미지',
				hq_videos: '고품질(HQ) 비디오',
				rendering_speed: '렌더링 속도 (이미지용)',
				resolution_4k_export: '4K 해상도 내보내기',
				file_3d_export: '3D 파일 내보내기 (GLTF/GLB)',
			},
			features: {
				features: '기능',
				all_premiums: '모든 프리미엄 3D 그래픽 (템플릿, 아이콘 & 장면)',
				free_only: '무료만',
				graphics_upload: '그래픽 업로드',
				fonts_upload: '폰트 업로드',
				custom_artboard_sizes: '맞춤 아트보드 크기',
				resolution_4k_export: '4K 해상도 내보내기',
				file_3d_export: '3D 파일 내보내기 (GLTF/GLB)',
			},
			credit_based_features: {
				credit_based_features: '크레딧 기반 기능',
				super_download: '슈퍼 다운로드',
				super_download_tooltip:
					'슈퍼 다운로드는 상세하고 전문적인 이미지/비디오를 위한 <br> 고품질 레이 트레이싱, 그림자 및 선명도를 제공합니다. <br> &#x2022; 이미지당 1 크레딧 <br>  &#x2022; 비디오 0.1초당 1 크레딧',
				rendering_speed: '렌더링 속도 (이미지용)',
				pixcap_ai: 'Pixcap AI',
				pixcap_ai_tooltip: 'AI 생성당 1 크레딧',
				custom_ai_prompt: '맞춤 AI 프롬프트',
				private_ai_output: '비공개 AI 출력',
				background_remover: '배경 제거기',
				monthly_credits: '월간 크레딧',
				super_and_ai: '(슈퍼 다운로드 & Pixcap AI)',
				unlimited: '무제한',
				medium: '중간',
				fast: '빠름 (3배)',
			},
			ai_style_transfer: {
				ai_style_transfer: 'AI 스타일 변환',
				tooltip: 'Pixcap AI를 사용하여 아이콘을 매트, 광택, 패브릭, 유리, 부풀린 스타일 등 다양한 스타일로 즉시 변환하세요.',
				ai_style_transfer_usage: 'AI 스타일 변환 사용',
				custom_ai_prompt: '맞춤 AI 프롬프트',
				bg_removal: '배경 제거',
			},
			licensing: {
				licensing: '라이선싱',
				personal_use: '개인 사용',
				commercial_use: '상업적 사용',
				watermark: '워터마크',
			},
			tailored_services: {
				tailored_services: '맞춤형 서비스',
				modeling_services: '3D 모델링 서비스',
				custom_animations: '맞춤 애니메이션',
				custom_mockups: '맞춤 목업',
				dedicated_support: '전담 지원',
			},
			support: '지원',
			faq: {
				pricing: '가격',
				faqs: '자주 묻는 질문',
				what_is_hq_download: '고품질 다운로드란 무엇인가요?',
				what_is_hq_download_answer:
					'내보낸 이미지와 비디오의 품질이 훨씬 더 높아지며, 향상된 레이 트레이싱과 더 사실적인 그림자 및 조명을 제공합니다.',
				what_is_super_download: '슈퍼 다운로드란 무엇인가요?',
				what_is_super_download_answer:
					'슈퍼 형식으로 내보낸 이미지와 비디오의 품질이 훨씬 더 높아지며, 향상된 레이 트레이싱과 더 사실적인 그림자 및 조명을 제공합니다.',
				can_i_use_pixcap_for_free: 'Pixcap을 무료로 사용할 수 있나요?',
				can_i_use_pixcap_for_free_answer:
					'물론입니다! Pixcap은 모든 사용자에게 무료 플랜을 제공합니다. 비용 없이 모든 템플릿과 콘텐츠를 탐색할 수 있습니다. PRO 에셋이나 템플릿을 원하거나, AI 기능을 강화하거나, 애니메이션 목업 도구를 사용하고 싶다면 필요에 따라 Pro 또는 Elite 플랜으로 업그레이드할 수 있습니다. 유료 플랜을 사용하면 슈퍼 다운로드, 4K 다운로드, GLB 파일 다운로드, 모든 콘텐츠 액세스, 애니메이션 3D 목업 실험 등을 할 수 있습니다.',
				will_my_subscription_automatically_renew: '구독이 자동으로 갱신되나요?',
				will_my_subscription_automatically_renew_answer:
					'네, 월간 또는 연간 플랜에 따라 수동으로 취소하기 전까지 구독이 자동으로 갱신됩니다. 다음 결제 주기까지 플랜 혜택을 계속 이용할 수 있습니다.',
				what_are_pixcap_credits: 'Pixcap 크레딧이란 무엇인가요?',
				what_are_pixcap_credits_answer: `Pixcap 크레딧을 통해 Pixcap의 모든 잠재력을 실험해볼 수 있습니다! 기본적으로 슈퍼 다운로드, Pixcap AI, 슈퍼 다운로드 애니메이션 내보내기에 크레딧이 필요하며 각 도구는 특정 양의 크레딧을 사용합니다. 예를 들어, 슈퍼 렌더링을 사용한 정적 이미지 1개 내보내기는 1 크레딧이며, 슈퍼 렌더링 애니메이션 1초 내보내기는 {amount} 크레딧입니다. Pixcap 크레딧 페이지에서 크레딧에 대해 자세히 알아볼 수 있습니다.`,
				how_do_my_pixcap_credits_renew: 'Pixcap 크레딧은 어떻게 갱신되나요?',
				how_do_my_pixcap_credits_renew_answer:
					'네, 각 무료 사용자는 월 30개의 무료 Pixcap 크레딧을 받습니다. 더 많은 크레딧이 필요하지만 구독을 원하지 않는 경우 충전 번들 중 하나를 구매할 수 있습니다.',
				do_free_users_also_have_pixcap_credits: '무료 사용자도 Pixcap 크레딧을 가지고 있나요?',
				do_free_users_also_have_pixcap_credits_answer:
					'Pixcap 크레딧은 매월 1일에 월간 단위로 초기화됩니다. 이전 달의 사용하지 않은 모든 크레딧은 소멸되므로 모두 사용하도록 하세요!',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles: 'Pro 또는 Elite 사용자도 충전 번들을 구매할 수 있나요?',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles_answer:
					'네! 구독 크레딧을 모두 사용한 경우 충전 번들을 구매할 수 있습니다 - 월간 한도가 없으므로 필요한 만큼 구매할 수 있습니다!',
				can_i_use_pixcap_for_commercial_purposes: 'Pixcap을 상업적 목적으로 사용할 수 있나요?',
				can_i_use_pixcap_for_commercial_purposes_answer:
					'모든 내보낸 콘텐츠는 상업적으로 사용할 수 있습니다. 무료 사용자는 출처를 표시해야 합니다. PRO & ELITE 사용자는 출처를 표시할 필요 없이 모든 Pixcap 콘텐츠를 프로젝트에서 상업적으로 사용할 수 있습니다.',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription: '구독을 취소하면 다운로드한 항목에 대한 접근 권한을 잃게 되나요?',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription_answer: '구독 여부와 관계없이 다운로드한 항목은 영구적으로 계정에 유지됩니다.',
				what_is_your_refund_policy: '환불 정책은 어떻게 되나요?',
				what_is_your_refund_policy_answer:
					'Pixcap 구독에 대한 결제는 환불이 불가능합니다. 단, EU나 터키에 거주하는 경우, 구매 후 14일 이내에 구독을 취소하고 <span class="text-weight-700">서비스를 사용하지 않았다면(즉, Pixcap에서 어떤 리소스도 다운로드하지 않았다면)</span> 환불 자격이 있을 수 있습니다.' +
					'<div class="mt-m">환불을 요청하려면 <span class="text-color-primary-5 text-underline">support@pixcap.com</span>으로 이메일을 보내주시고 거주 국가를 포함해 주세요.</div>',
			},
			testimonials: {
				testimonials: '고객 후기',
				header: '고객들이 우리에 대해 하는 말',
			},
			do_more: {
				do_more: '더 많은 기능',
				header: '업그레이드하고 강력한 3D & AI 디자인 도구의 전체 제품군을 탐색하세요',
				text: 'Pixcap은 맞춤형 3D 아이콘, 그래픽, 일러스트레이션을 제공하는 브라우저 기반 플랫폼입니다. 많은 기능이 무료로 제공되어 모든 3D 편집 요구 사항을 위한 원스톱 샵을 제공합니다. 계정 생성은 필수는 아니지만 창작물을 저장할 수 있어 언제든지 아이콘을 편집하고 다운로드할 수 있으므로 권장됩니다.',
				upgrade_now: '지금 업그레이드',
			},
		},
		user_settings: {
			account_settings: {
				header: '계정 설정',
				email_address: '이메일 주소',
				change_photo: '사진 변경',
				text: '계정 세부 정보, 프로필 등을 확인하고 업데이트하세요.',
				profile_photo: '프로필 사진',
				account_settings: '계정 설정',
				display_name: '표시 이름',
				bio: '자기소개',
				bio_placeholder: '자신을 소개해 주세요',
				save_photo: '이미지 저장',
			},
			invite_friends: {
				invite_friends: '친구 초대',
				header: '친구를 초대하고 즉시 보상을 받으세요',
				text: '초대 링크를 친구와 공유하고 획득한 보상을 추적하세요.',
				invite: '초대하기',
				by_link: '링크로',
				copy: '링크 복사',
				by_email: '이메일로',
				enter_email: '이메일 주소 입력',
				send: '이메일 보내기',
				invitation_sent: '초대장 발송 완료!',
				how_it_works: '작동 방식',
				note: 'Pixcap 추천 프로그램이 종료되었습니다. 아래의 획득한 보상은 프로그램 종료 전에 참여한 사람들에게만 해당됩니다.',
				earned_rewards: '획득한 보상',
				referrals: '추천',
				date: '날짜',
				status: '상태',
				earnings: '수익',
				no_rewards: '획득한 보상이 없습니다.',
				tooltip: {
					pending: `{start_date}-{expired_date} 사이에 <br> 친구가 가입하기를 기다리는 중입니다.`,
					joined: '친구가 적어도 1개의 디자인을 <br> 다운로드하기를 기다리는 중입니다.',
				},
				claimed: '신청됨',
				pending: '대기 중',
			},
			subscription: {
				text: '구독을 확인, 관리 또는 취소하세요.',
				billed_annually: '연간 청구',
				per_month: '월',
				per_day: '일',
				free_text: '이 플랜은 제한된 에셋과 기능을 제공합니다.',
				free_text_2: '15,000개 이상의 프리미엄 에셋 & HQ 다운로드에 대한 무제한 액세스를 얻으려면 업그레이드하세요.',
				downloads: '다운로드',
				downloads_limit: {
					default: '기본 품질 이미지 + 비디오',
					hq_image: '고품질 이미지',
					hq_video: '고품질 비디오',
				},
				ai_style: 'AI 스타일 변환',
				ai_limits: {
					ai_uses: 'AI 사용',
				},
				topped_up: '충전됨',

				subscription: '구독',
				renew_plan: '플랜 갱신',
				plan: '플랜',
				on_free: '현재 무료 계정을 사용 중입니다',
				sub_expired: '**구독이 만료되었습니다.',
				payment_unsucess: '**결제에 실패했습니다. 다시 구독해 주세요.',
				payment_pending: '**결제가 대기 중입니다. 결제를 계속하려면 확인해 주세요.',
				payment_invalid: '**결제가 유효하지 않습니다. 결제 정보를 확인하고 업데이트해 주세요.',
				plan_to_renew: '**갱신할 플랜을 선택해 주세요.',
				check_payment: '결제 확인',
				go_to_payment: '결제 페이지로 이동',
				choose_a_plan: '플랜 선택',
				complete_payment_in_your_plan: '{plan} 계속하려면 결제를 완료하세요',
				cancel_plan: '플랜 취소',
				change_plan: '플랜 변경',
				plan_end_on: '플랜 종료일',
				next_payment: '다음 결제',
				view_all_invoice: '모든 인보이스 보기',
				credits: '크레딧',
				current_monthly_credits: '현재 월간 구독 크레딧 잔액',
				credit_note: '이 크레딧은 매월 초기화되는 구독의 일부입니다.',
				topup_balance: '충전 크레딧 잔액',
				topup: '크레딧 충전',
				note: '충전을 통해 획득한 크레딧입니다. 이 크레딧은 월간 제한이 <br />없으며 구독 크레딧이 사용 가능한 동안에는 사용되지 않습니다.',
				per_year: '연간',
				for_3_months: '3개월',
				get_more_create_more: '더 많이 얻고, 더 많이 만드세요!',
				save_more: '연간 플랜으로 더 많이 절약하세요',
				upgrade_plan: '플랜 업그레이드',
				unlock_all: '모든 기능 잠금 해제',
				topup_modal: {
					choose_a_bundle: '번들 선택',
					credits: '크레딧',
					text: '아래 <strong>3개 중 1개</strong> 제안과 동일합니다:',
					super_render_images: '슈퍼 렌더 이미지',
					super_render_videos: '슈퍼 렌더 비디오',
					images: '{credits} 이미지',
					seconds: '{credits} 초',
					ai: 'AI 생성',
					generations: '{credits} 생성',
					amount: '금액',
					purchase_now: '지금 구매',
				},
				cancel_sub_modal: {
					cancel_sub: '구독 취소',
					sub: '{plan} 플랜이 <strong>{expireDay}</strong>에 만료되면 모든 프리미엄 기능을 잃게 됩니다',
					sub_alt: '취소하면 즉시 모든 프리미엄 기능을 잃게 됩니다.',
					let_us_know: '떠나시는 이유를 알려주세요',
					write_feedback: '피드백을 작성해 주세요...',
					confirmed: '취소가 확인되었습니다!',
					update_text: '구독 업데이트에 약간의 시간이 걸릴 수 있습니다.',
					enjoy:
						'<strong>{expireDay}</strong>까지 모든 Pixcap {plan} 기능을 계속 즐기실 수 있습니다. 그 이후에는 더 이상 프리미엄 기능에 액세스할 수 없습니다.',
					change_your_mind: '마음이 바뀌면 구독 설정에서 플랜을 갱신하는 것을 고려해 보세요 😊!',
					sad: '떠나신다니 안타깝습니다! <strong>무료</strong> 사용자로 Pixcap을 계속 즐기실 수 있습니다.',
					change_your_mind_2: '마음이 바뀌면 구독 설정에서 다시 구독하는 것을 고려해 보세요 😊!',
					keep: 'Pixcap {name} 유지하기',
					got_it: '알겠습니다',
					reason: {
						reason_1: '너무 비쌉니다',
						reason_2: '더 많은 기능이 필요합니다',
						reason_3: '비슷한 다른 웹사이트를 찾았습니다',
						reason_4: '자주 사용하지 않습니다',
						reason_5: '품질 좋은 리소스가 많지 않습니다',
						reason_6: `Pixcap {plan}이 가격만큼의 가치가 없다고 생각합니다`,
						reason_7: '비슷한 다른 웹사이트를 찾았습니다',
					},
				},
				plan_name: {
					pro_quarterly_plan: 'Pro 분기 플랜',
					elite_quarterly_plan: 'Elite 분기 플랜',
					free_plan: '무료 플랜',
					pro_annual_plan: 'Pro 연간 플랜',
					elite_annual_plan: 'Elite 연간 플랜',
					pro_monthly_plan: 'Pro 월간 플랜',
					elite_monthly_plan: 'Elite 월간 플랜',
				},
			},
			promotion: {
				promotion: '프로모션',
				text: '추천 할인 & 주간 무료 제공을 추적하세요.',
				only_one: '청구당 <span class="text-weight-600">1개</span>의 구독 기반 보상만 제공됩니다. 아래에서 가장 마음에 드는 것을 찾아보세요. 🌟',
				got_it: '알겠습니다',
				no_promotions: '사용 가능한 프로모션이 없습니다',
				watermark_promo: {
					title: '추천 무료 제공',
					description: '무료 에셋에 대한 무제한 워터마크 없는 다운로드',
					valid_until: '{date}까지 유효',
					no_expiration_date: '만료일 없음',
					unlimited_referrals: '무제한 추천',
					learn_more: '자세히 알아보기',
					claim: '신청하기',
					claimed: '신청됨',
				},
			},
			change_password: {
				change_password: '비밀번호 변경',
				current: '현재 비밀번호',
				current_placeholder: '현재 비밀번호 입력',
				new: '새 비밀번호',
				new_placeholder: '새 비밀번호 입력',
				confirm: '비밀번호 확인',
				confirm_placeholder: '새 비밀번호 재입력',
				error_1: '비밀번호는 비워둘 수 없습니다',
				error_2: '새 비밀번호는 비워둘 수 없습니다',
				error_3: '비밀번호 확인은 비워둘 수 없습니다',
				error_4: '비밀번호가 일치하지 않습니다',
				error_5: '비밀번호가 올바르지 않습니다',
			},
			email_notification: {
				text: '뉴스레터 및 계정 활동 알림에 대한 기본 설정을 설정하세요.',
				email_notification: '이메일 알림',
				alerts_and_newsletter: '알림 & 뉴스레터',
				downloads: '다운로드',
				downloads_text: '고품질 & AI 이미지 다운로드가 가능할 때 알림 받기',
				events: '이벤트',
				events_text: '선호하는 이벤트 팩이 게시될 때 알림 받기',
				newsletters: '뉴스레터',
				newsletters_text: '뉴스 & 디자인 영감을 위한 마케팅 뉴스레터 받기',
				account_activity: '계정 활동',
				email_when: '다음 경우에 이메일 보내기',
				comments: '누군가 내 프로젝트에 댓글을 달았을 때',
				mentions: '누군가 나를 언급했을 때',
				collab_invites: '프로젝트 협업 초대를 받았을 때',
				pending_actions: '대기 중인 작업',
				conversation: '프로젝트 내 대화',
			},
			save: '저장',
			save_changes: '변경 사항 저장',
		},
		shared_project: {
			search_placeholder: '공유된 프로젝트 검색...',
			title: '나와 공유됨',
		},
		contributor_profile: {
			pixcap: {
				display_name: 'Pixcap 팀',
				description:
					'UX/UI, 기술, 여행, 비즈니스, 교육, 음식, 스포츠 등을 위해 디자인된 15,000개 이상의 커스터마이징 가능한 3D 에셋에 접근하세요. 색상 변경, 회전, 포즈 조정, 효과 추가를 통해 아이콘, 일러스트레이션, 캐릭터를 진정으로 여러분의 것으로 만들 수 있습니다.',
			},
		},
	},
	payments: {
		method: '결제 수단',
		card_ewallets: '카드 / 전자지갑',
	},
	common_elements: {
		explore_item_type_banner: {
			icons: {
				title: '3D 아이콘 및 애니메이션 아이콘 커스터마이징',
				description:
					'3D 에디터를 사용하여 3D 아이콘의 색상, 재질, 애니메이션 스타일을 조정해보세요. Figma, Sketch, Adobe 등에서 사용할 수 있도록 무료 3D 아이콘을 PNG, GLB, GIF, MP4 형식으로 다운로드하세요.',
				btn_text: '3D 아이콘 탐색',
			},
			characters: {
				title: '3D 캐릭터에 생명 불어넣기',
				description: '3D 모델링 전문 지식 없이도 3D 캐릭터의 포즈를 설정하고, 색상 등을 수정할 수 있는 유일한 3D 포즈 설정 도구를 만나보세요.',
				btn_text: '3D 캐릭터 탐색',
			},
			mockups: {
				title: '애니메이션 3D 목업을 순식간에 만들기',
				description: '이제 목업을 완전히 커스터마이징할 수 있습니다 - 어떤 애니메이션 목업에도 라벨을 업로드하고 5분 이내에 4K로 다운로드하세요.',
				btn_text: '목업 탐색',
			},
		},
		status: {
			all: '모든 상태',
			completed: '완료됨',
			processing: '처리 중',
			failed: '실패',
			cancelled: '취소됨',
			preparing: '준비 중',
			removed: '제거됨',
		},
		packs_count: '{count} 팩',
		items_count: '{count} 항목',
		filter: {
			filters: '필터',
			type: {
				type: '유형',
				all: '전체',
				animated_static: '애니메이션 + 정적',
				animated: '애니메이션',
				static: '정적',
			},
			price: {
				price: '가격',
				all: '전체',
				premium: '프리미엄',
				free_paid: '무료 + 유료',
				free_premium: '무료 + 프리미엄',
				free: '무료',
				paid: '유료',
			},
			size: {
				size: '크기',
				all: '모든 크기',
			},
			pack_or_item: {
				view_as: '보기 방식',
				items: '항목',
				packs: '팩',
			},
		},

		all_assets: '모든 에셋',
		icons: '3D 아이콘',
		characters: '3D 캐릭터',
		mockups: '목업',
		premium: '프리미엄',
		asset_packs: '3D 에셋 팩',
		icon_packs: '3D 아이콘 팩',
		character_packs: '3D 캐릭터 팩',
		mockup_pack: '목업 팩',

		edit: '편집',
		high_quality: '고품질',
		default: '기본',
		subscribe_and_edit: '구독 및 편집',
		get_elite_for_unlimited_access: '무제한 액세스를 위해 Elite 구독하기',
		upgrade_to_elite: 'Elite로 업그레이드',
		unlock_all_access: '모든 액세스 잠금 해제',
		explore_now: '지금 탐색',
		by: '제작:',
		new: '신규',
		copy_link: '링크 복사',
		link_copied: '링크가 복사되었습니다',

		or: '또는',
		show_all: '모두 보기',
		view_all: '모두 보기',
		view_more: '더 보기',
		free: '무료',
		purchased: '구매됨',
		animated: '애니메이션',
		editable: '편집 가능',
		tags: {
			all: '전체',
			trending: '트렌딩',
		},
		coming_soon: '출시 예정',
		get_started: '시작하기',

		more: '더 보기',
		edit_in_3d: '3D로 편집',
		view_details: '상세 보기',
		read_more: '더 읽기',
		download: '다운로드',
		open: '열기',

		delete_modal: {
			title: '{entity} 삭제',
			message: '이 {entity}을(를) 삭제하시겠습니까?',
			delete: '삭제',
		},
		empty: {
			with_search: {
				message: '검색 결과가 없습니다',
				sub_message: "'{search}' {type}을(를) 디자인해 드릴까요? 다음으로 보내주세요",
			},
			request: '요청',
			message: '죄송합니다! 현재 이 곳은 비어 있습니다.',
			sub_message: '필터를 조정하거나 나중에 다시 확인해 주세요',
		},
		cancel: '취소',
		error_404: '죄송합니다, 찾으시는 페이지를 찾을 수 없습니다.',
		error_500: '문제가 발생했습니다. 나중에 다시 시도해 주세요.',
		under_maintain: '현재 예정된 유지 보수를 수행 중입니다. \n 곧 다시 온라인 서비스를 제공할 예정입니다. 기다려 주셔서 감사합니다.',
		error_password_length: '비밀번호는 최소 8자 이상의 문자나 숫자로 구성되어야 합니다 (공백 제외).',
		error_password_not_matched: '비밀번호 확인이 일치하지 않습니다.',
		back_to_explore: '탐색 페이지로 돌아가기',
		retry: '다시 시도',
		render_type: {
			cloud: '클라우드 렌더',
			default: '기본 렌더',
			ai: 'AI 스타일',
		},
		remove: '제거',
	},
	toast: {
		error: '문제가 발생했습니다. 새로고침 후 다시 시도해 주세요.',
		success_delete_project: `프로젝트 {name}이(가) 성공적으로 삭제되었습니다`,
	},
	tag_name: {
		essential: '필수',
		brand_kit: '브랜드 키트',
		device: '디바이스',
		fintech: '핀테크',
		ecommerce: '이커머스',
		healthcare: '헬스케어',
		business: '비즈니스',
		casual: '캐주얼',
		profession: '전문직',
		laptop: '노트북',
		smartphone: '스마트폰',
		branding: '브랜딩',
	},
	seo_meta: {
		customizable: '커스터마이징 가능한',
		free: '무료',
		animated: '애니메이션',
		text_3d_animated_icons: '3D 애니메이션 아이콘',
		text_3d_icons_illus: '3D 아이콘 & 일러스트레이션',
		text_animated_icons: '애니메이션 아이콘',
		text_icons_logos_symbols: '아이콘, 로고, 심볼',

		title_item_detail: `{keyWord} PNG, GLB, GIF, MP4 다운로드 - Pixcap`,
		description_item_illustration_detail: `디자인 프로젝트를 위한 커스터마이징 가능한 {keyWord} ✓ 100개 이상의 관련 3D 일러스트레이션 ✓ 고해상도 ✓ 상업적 사용 무료`,
		description_item_character_detail: `디자인 프로젝트를 위한 커스터마이징 가능한 {keyWord} ✓ 100개 이상의 관련 3D 캐릭터 ✓ 고해상도 ✓ 상업적 사용 무료`,
		description_item_icon_detail: `디자인 프로젝트를 위한 커스터마이징 가능한 {keyWord} ✓ 100개 이상의 관련 3D 아이콘 ✓ 고해상도 ✓ 상업적 사용 무료`,
		description_item_mockup_detail: `디자인 프로젝트를 위한 커스터마이징 가능한 {keyWord} ✓ 100개 이상의 관련 3D 목업 ✓ 고해상도 ✓ 상업적 사용 무료`,
		description_item_design_detail: `디자인 프로젝트를 위한 커스터마이징 가능한 {keyWord} ✓ 100개 이상의 관련 3D 디자인 ✓ 고해상도 ✓ 상업적 사용 무료`,

		title_brandkit_detail: `비즈니스를 위한 브랜딩 목업 템플릿 - Pixcap`,
		description_brandkit_detail: `무료 & 프리미엄 브랜딩 목업 템플릿으로 온라인에서 멋진 브랜드 아이덴티티를 만들어보세요. 로고, 문구류, 디바이스, 사무용품 등에 디자인을 시각화해보세요.`,
		title_pack_detail: `{packKeyWord} 아이콘 팩 | {packSize} 3D 아이콘 & 일러스트레이션 다운로드`,
		description_pack_detail: `디자인 프로젝트를 위한 커스터마이징 가능한 {packKeyWord} 3D 아이콘 팩 ✓ 100개 이상의 관련 아이콘 팩 ✓ 고해상도 ✓ 상업적 사용 무료`,

		title_library_icons: '{currentPlanQuery} {currentTypeQuery} {currentTagQuery} 3D 아이콘 팩 - PNG, GLB, GIF, MP4 무료 다운로드',
		description_library_icons: `그래픽 디자인, UX/UI, 프레젠테이션 등을 위한 {currentPlanQuery} {currentTypeQuery} {currentTagQuery} 3D 아이콘 팩 찾기 ✓ 커스터마이징, 다운로드, 공유 가능 ✓ 고해상도 ✓ 상업적 사용 무료`,

		title_library_characters: `{currentPlanQuery} {currentTypeQuery} {currentTagQuery} 3D 캐릭터 - PNG, GLB, GIF, MP4 무료 다운로드`,
		description_library_characters: `그래픽 디자인, UX/UI, 프레젠테이션 등을 위한 {currentPlanQuery} {currentTypeQuery} {currentTagQuery} 3D 캐릭터 찾기 ✓ 커스터마이징, 다운로드, 공유 가능 ✓ 고해상도 ✓ 상업적 사용 무료`,

		title_library_illusions: `{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery} 일러스트레이션 - PNG, GLB, GIF, MP4 무료 다운로드`,
		description_library_illusions: `그래픽 디자인, UX/UI, 프레젠테이션 등을 위한 {currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery} 일러스트레이션 찾기 ✓ 커스터마이징, 다운로드, 공유 가능 ✓ 고해상도 ✓ 상업적 사용 무료`,

		title_library_mockups: `{currentPlanQuery} {currentTypeQuery} {currentTagQuery} 3D 목업 - PNG, GLB, GIF, MP4 무료 다운로드`,
		description_library_mockups: `UX/UI, 포트폴리오, 프레젠테이션 등을 위한 {currentPlanQuery} {currentTypeQuery} {currentTagQuery} 3D 목업 찾기 ✓ 커스터마이징, 다운로드, 공유 가능 ✓ 고해상도 ✓ 상업적 사용 무료`,
		title_library_mockups_default: `순식간에 3D 목업 & 애니메이션 3D 목업 만들기`,
		description_library_mockups_default: `제품, 포스터, 명함, 책 표지 등을 위한 사실적인 3D 목업 이미지와 비디오를 생성하세요. 온라인에서 사용 가능한 무료 목업 생성기입니다.`,

		title_library_designs: '3D 아이콘, 일러스트레이션, 목업 - PNG, GLB, GIF, MP4 무료 다운로드',
		description_library_designs:
			'그래픽 디자인, 프레젠테이션, 웹 프로젝트를 위한 10,000개 이상의 3D 요소 탐색 및 다운로드 ✓ 고해상도 ✓ 상업적 사용 무료',

		title_search_prefix: ' - PNG, GLB, GIF, MP4 무료 다운로드',
		title_search_pack_list: `{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery} 3D 아이콘 팩 - PNG, GLB, GIF, MP4 무료 다운로드`,
		description_search_pack_list: `{currentPlanQuery}{searchWord} 3D {currentTypeQuery} 아이콘 팩 커스터마이징 & 다운로드 ✓ 고해상도 ✓ 상업적 사용 무료 ✓ 매일 새로운 팩 추가`,
		title_search_icon_list: `{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery} - PNG, GLB, GIF, MP4 무료 다운로드`,
		description_search_icon_list: `{currentPlanQuery}{searchWord} 3D {currentTypeQuery} 커스터마이징 & 다운로드 ✓ 고해상도 ✓ 상업적 사용 무료 ✓ 매일 새로운 팩 추가`,
		title_search_scene_list: `{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery}3D 일러스트레이션 - PNG, GLB, GIF, MP4 무료 다운로드`,
		description_search_scene_list: `{currentPlanQuery}{searchWord} 3D {currentTypeQuery} 일러스트레이션 커스터마이징 & 다운로드 ✓ 고해상도 ✓ 상업적 사용 무료 ✓ 매일 새로운 팩 추가`,
		title_search_mockup_list: `{totalItems} {searchWord} 3D 목업 - PNG, GLB, GIF, MP4 무료 다운로드`,
		description_search_mockup_list: `{searchWord} 3D 목업 커스터마이징 & 다운로드 ✓ 고해상도 ✓ 상업적 사용 무료 ✓ 매일 새로운 3D 목업 추가`,
		title_search_template_list: `{totalItems} {searchWord} 3D 디자인 - PNG, GLB, GIF, MP4 무료 다운로드`,
		description_search_template_list: `{searchWord} 3D 디자인 커스터마이징 & 다운로드 ✓ 고해상도 ✓ 상업적 사용 무료 ✓ 매일 새로운 3D 디자인 추가`,
		contributor_page: {
			title: '{name} 3D 디자이너 포트폴리오 | Pixcap',
			description:
				'{name}의 고품질 3D 디자인 리소스를 탐색하고 다운로드하세요. Pixcap의 디자이너 커뮤니티와 더 많은 아름다운 3D 에셋을 지금 탐색해보세요.',
		},
	},
};

export default kr;
