import { AppLang } from '@/lang/_template.type';

const jp: AppLang = {
	app_name: 'Pixcap',
	promo_banner: {
		title: 'プロモコード：<span class="text-weight-600">{promo_code}!</span>で、ProとEliteの年間プランが{discount}%オフでお楽しみいただけます',
		cta_text: '{discount}%オフを取得',
	},
	navbar: {
		search_placeholder: '検索...',
		search_dropdown: {
			recent_search: '最近の検索',
			popular_search: '人気の検索',
			suggested_search: 'おすすめの検索',
		},
		account: 'アカウント',
		navigation_items: {
			browse: '閲覧',
			asset: '3Dアセット',
			asset_dropdown: {
				icons_title: '3Dアイコン',
				icons_description: '15,000以上の無料およびプレミアム3Dアイコンとイラスト',
				characters_title: '3Dキャラクター',
				characters_description: '1,000以上の無料およびプレミアム3Dキャラクター',
				mockups_title: 'モックアップ',
				mockups_description: '500以上の無料およびプレミアムモックアップとブランディングモックアップキット',
			},
			tools: 'ツール',
			tools_dropdown: {
				prefix: '最高のAIツール',
				ai_3d_model: 'AI 3Dモデルジェネレーター',
				ai_character: 'AIキャラクタージェネレーター',
				ai_girl: 'AI女性ジェネレーター',
				ai_background: 'AI背景ジェネレーター',
				ai_filter: 'AIフィルター',
				ai_icon: 'AIアイコンジェネレーター',
				icon_maker: '3Dアイコンメーカー',
				animated_icon: 'アニメーションアイコン',
				character_creator: '3Dキャラクタークリエーター',
			},
			pricing: '料金プラン',
		},

		language_switcher: {
			tooltip: '言語',
		},

		my_projects: 'マイプロジェクト',

		login: 'ログイン',
		notification_dropdown: {
			notifications: '通知',
			empty: '通知はありません',
		},
		download_dropdown: {
			full_download_history: '完全なダウンロード履歴',
			tooltip: {
				download_history: 'ダウンロード履歴',
				download_completed: 'ダウンロード完了',
			},
			show_all_downloads: 'すべてのダウンロードを表示',
			empty: 'ここは空です。\n（高品質）ダウンロードを試して\n魔法が起こる場所を見てください。',
			upgrade: {
				enjoy: 'Pro/Eliteユーザーは<span class="text-color-pro">3倍速いレンダリング</span>を楽しめることをご存知ですか？',
				text: '今すぐアップグレードして、高品質ダウンロードの速度アップを！',
				upgrade_now: '今すぐアップグレード',
			},
			cancel_download_modal: {
				cancel_download: '高品質ダウンロードのキャンセル',
				text: 'このビデオに使用された残高は失われます。本当に続行しますか？',
				text_gif: 'このGIFに使用された残高は失われます。本当に続行しますか？',
				confirm: '確認',
			},
		},
		user_dropdown: {
			projects: 'プロジェクト',
			downloads: 'ダウンロード',
			account_settings: 'アカウント設定',
			invite_friends: '友達を招待',
			contact_us: 'お問い合わせ',
			shared_project: '共有プロジェクト',
			contributor_files: '貢献者ファイル',
			sign_out: 'サインアウト',
		},
		login_form: {
			title: '15,000以上のカスタマイズ可能な3Dアセットをアンロック',
			description: '高品質の3Dアニメーションアイコン、3Dキャラクター、モックアップ。',
			google_login: 'Googleで続ける',
			create_account: 'アカウントを作成',
			create_account_heading: 'Pixcapアカウントを作成しています',
			accept_term: '続行することで、以下に同意したことになります：',
			next: '次へ',
			verify: '確認',
			back: '戻る',
			email_login: {
				email: 'メールアドレスを入力',
				password: 'パスワードを入力',
				confirm_password: '新しいパスワードを確認',
				resend: '再送信',
				email_login: 'メールアドレスで続ける',
				enter_email: 'メールアドレスを入力',
				enter_verification_code: '確認コードを入力',
				verification_sent: '確認コードを以下に送信しました：',
				enter_email_message: 'サインインします。アカウントをお持ちでない場合は<br />新しく作成します。',
				verify_email: 'メールアドレスを確認',
				check_email: '<span class="text-color-primary-5">{{ email }}</span>に送信された確認コードをご確認ください。',
				invalid_code: '入力されたコードが無効です。もう一度お試しください',
				did_not_received: 'メールを受け取っていませんか？',
				error_email: '有効なメールアドレスを入力してください。',
				error_E02_02_001: 'メールアドレスまたはパスワードが正しくありません。',
				error_E02_02_002: 'このメールアドレスは既に使用されています',
				error_E02_02_003: 'エラーが発生しました。もう一度お試しください',
				error_default: 'ログインに失敗しました。もう一度お試しください',
			},
		},
		forgot_pw_form: {
			forgot_pw: 'パスワードをお忘れですか？',
			sub: 'パスワードをリセットするために、メールアドレスを入力してください。',
			check_your_email: 'パスワードリセットコードを<strong>{email}</strong>に送信しました。',
			sub_text: 'パスワードリセットリンクをメールで送信しました。',
			did_not_received: 'メールを受け取っていませんか？',
			enter_reset_code: 'リセットコードを入力',
			reset_password: 'パスワードをリセット',
			error_email: 'メールアドレスが無効です',
			change_password: 'パスワードを変更',
			continue: '続ける',
		},
		contact_us_form: {
			contact_us: 'お問い合わせ',
			name: '名前',
			enter_your_name: 'お名前を入力',
			email: 'メールアドレス',
			enter_your_email: 'メールアドレスを入力',
			message: 'メッセージ',
			write_message: 'ここにメッセージを入力してください',
			send: 'メッセージを送信',
			error_name: '**名前を入力してください',
			error_email: '**メールアドレスが無効です',
		},
	},
	sidebar: {
		home: 'ホーム',
		library: 'ライブラリ',
		my_projects: 'マイプロジェクト',
		my_assets: 'マイアセット',
		pricing: '料金プラン',
		unlock_all_features: 'すべての機能をアンロック',
		upgrade_plan: 'プランをアップグレード',
	},
	breadcrumb: {
		home: 'ホーム',
		mockups: 'モックアップ',
		brandkit_mockups: 'ブランドキットモックアップ',
		icon_packs: '3Dアイコンパック',
		illustrations: 'イラスト',
		designs: 'デザイン',
	},
	footer: {
		title: 'もっと3Dコンテンツを探す',
		searched_title: '関連する検索をブラウズ',
		seo_section: {
			sort_by: {
				latest: '最新',
				trending: '人気',
			},
		},
		footer_section: {
			explore: {
				title: '探索',
			},
			features: {
				title: '機能',
				animated_mockup: 'アニメーションモックアップ',
				ai_style_transfer: 'AIスタイル転送',
				character_library: 'キャラクターライブラリ',
				material_library: 'マテリアルライブラリ',
			},
			company: {
				title: '会社情報',
				pricing: '料金プラン',
				enterprise: '企業向け',
				privacy_policy: 'プライバシーポリシー',
				terms_of_service: '利用規約',
				contact_us: 'お問い合わせ',
			},
			resources: {
				title: 'リソース',
				glossary: '用語集',
				contributor: 'コントリビューターになる',
			},
			tools: {
				title: 'ツール',
				ai_3d_model: 'AI 3Dモデルジェネレーター',
				ai_character: 'AIキャラクタージェネレーター',
				ai_girl: 'AI女性ジェネレーター',
				ai_background: 'AI背景ジェネレーター',
			},
			create: {
				title: '制作',
				mug_design: 'マグカップデザイン',
				sticker_design: 'ステッカーデザイン',
				friendsgiving_invitation: 'フレンズギビング招待状',
				poster_mockup: 'ポスターモックアップ',
				iphone_mockup: 'iPhoneモックアップ',
			},
			blogs: {
				title: 'ブログ',
				all_blogs: 'すべてのブログ',
			},
			follow_us: {
				title: 'フォローする',
			},
		},
	},

	page: {
		home: {
			title: '唯一の<br> <span class="text-weight-600 text-color-primary-5">編集可能な3Dアセット</span>マーケットプレイス',
			description: 'ウェブブラウザ上で高品質な3Dアイコン、3Dキャラクター、モックアップ、3Dイラストを編集できます。',
			partners: {
				title: '以下の企業チームで使用されています',
			},
			interactive_banner: {
				title: '簡単にユニークな3D要素を作成',
				description: 'シンプルなエディターを使って、任意の3Dアセットの色、素材、アニメーションスタイルを調整してみてください。',
				default: 'デフォルト',
				color: '色',
			},
			animated_icon_pack: 'アニメーション3Dアイコン',
			animated_icon_pack_description: '色の変更、カメラの回転、素材の調整などでアニメーション3Dアイコンをカスタマイズできます。',
			icon_pack: '3Dアイコン',
			icon_pack_description: '色の変更、カメラの回転、素材の調整などで3Dアイコンをカスタマイズできます。',
			characters_pack_description: 'ポーズや色を変更して、ユニークな3Dキャラクターと3Dイラストを作成できます。',
			device_mockup_pack_description:
				'ラベルをアップロードして、ユニークなUX/UIモックアップを作成。色をカスタマイズし、MP4、PNG、GIFでダウンロードできます。',
			branding_mockup_pack_description:
				'ラベルをアップロードして、ユニークなブランディングモックアップを作成。色をカスタマイズし、MP4、PNG、GIFでダウンロードできます。',
			latest_icon_pack: '最新の3Dアイコン',
			characters_pack: '3Dキャラクター',
			device_mockup_pack: 'デバイスモックアップ',
			branding_mockup_pack: 'ブランディングモックアップ',
			advertise: {
				title: 'プロジェクトに最適な3Dデザインツール',
				description: 'ウェブデザイン、モバイルアプリ、マーケティングなどに3Dモデルを編集して使用できます',
				first_card: {
					title: 'ウェブサイト＆モバイルアプリ',
					description:
						'3D要素を使用してプロジェクトのデザインを向上させます。ユーザーの注目を集め、ウェブサイトやアプリを競合他社から際立たせましょう。',
				},
				second_card: {
					title: 'アニメーションモックアップ',
					description:
						'あなたのデザインを任意のアニメーションモックアップにアップロードし、背景を好みに合わせて編集し、5分以内に4Kでダウンロードできます。',
				},
				third_card: {
					title: 'プレゼンテーション',
					description:
						'プレゼンテーションに3Dキャラクターと3Dアイコンを活用します。ビジネスピッチや教育プレゼンテーションをより魅力的でダイナミックにします。',
				},
			},
			compatible: {
				title: 'お気に入りのソフトウェアと互換性あり',
				description: 'Pixcapのコンテンツを任意のファイル形式（GLB、MP4、GIF、PNG）でダウンロードして、お気に入りのソフトウェアですべて使用できます。',
			},

			banner: {
				title_desktop: `3Dコンテンツをカスタマイズして<br>始めましょう！`,
				title_mobile: `3Dコンテンツの<br>編集を始めましょう！`,
				button_text: '今すぐ探索',
			},

			header1: 'すべての3Dグッズはカスタマイズ可能 - 今すぐ試してみてください！',
			trending_icons: '人気のアイコン',
			essential_icon: '必須アイコンパック',
			essential_illustrations: '必須イラストレーションキット',
			essential_mockups: 'リアルなデバイス＆ブランディングモックアップ',

			header2: 'さらに編集可能な3Dクリエイティブを閲覧',
			fintech_illustrations: 'フィンテックイラスト',
			ecommerce_illustrations: 'Eコマースイラスト',
			healthcare_illustrations: 'ヘルスケアイラスト',
			browse_all_illustrations: 'すべてのイラストを閲覧',
			business_characters: 'ビジネスキャラクター',
			casual_characters: 'カジュアルキャラクター',
			profession_characters: '職業キャラクター',
			browse_all_characters: 'すべてのキャラクターを閲覧',
			laptop_mockups: 'ラップトップモックアップ',
			smartphone_mockups: 'スマートフォンモックアップ',
			branding_mockups: 'ブランディングモックアップ',
			browse_all_mockups: 'すべてのモックアップを閲覧',
		},
		library: {
			banner: {
				title_icons: '3D{animate}{tag}アイコンパックをパーソナライズ＆編集',
				title_characters: '3D{tag}キャラクターを編集＆ポーズ付け',
				title_mockups: 'デバイス＆ブランディング{tag}モックアップにデザインを適用',
				description_icons:
					'エディターを使用して3D{animate}アイコンの色、素材、アニメーションスタイルを調整してみてください。Figma、Sketch、Adobeなどで使用するための無料3DアイコンをPNG、GLB、GIF、MP4形式でダウンロードできます。',
				description_characters:
					'3Dモデリングの専門知識がなくても、3Dキャラクターのポーズ付け、色の変更などができる唯一の3Dポーズツールを見つけました。',
				description_mockups:
					'モックアップが完全にカスタマイズ可能になりました - あなたのラベルを任意のアニメーションモックアップにアップロードし、5分以内に4Kでダウンロードできます。',
			},
			title: {
				prefix_customize: 'カスタマイズ',
				prefix_free: '無料',
				prefix_premium: 'プレミアム',
				prefix_free_and_premium: '無料＆プレミアム',
				icons: '3Dアイコンパック',
				characters: '3Dキャラクター',
				mockups: 'モックアップ',
			},
			description: {
				icons:
					'ウェブサイト、アプリ、グラフィックデザイン、マーケティング用の{count}{premium}{animated}{tag}高品質アイコンセットから選択できます。アイコンをカスタマイズし、PNG、GLB、GIF、MP4形式でデザインプロジェクト用にダウンロードできます。',
				characters:
					'{premium}{tag}キャラクターイラストでユーザーエンゲージメントとストーリーテリングを向上させましょう。カスタマイズして、ソーシャルメディア投稿、プレゼンテーション、説明ビデオ、製品デモなどで使用できます。',
				mockups:
					'{premium}{animated}{tag}リアルなモックアップであなたのデザインとプレゼンテーションを強化しましょう。簡単にカスタマイズでき、製品、ブランディング、パッケージングなどの展示に最適です。',
			},
			header: '完全にカスタマイズ可能な3Dコンテンツを探索しよう！',
			cards: {
				icons: {
					icons: 'アイコン',
					browse_all: 'すべての3Dアイコンを閲覧',
				},
				characters: {
					characters: 'キャラクター',
					browse_all: 'すべての3Dキャラクターを閲覧',
				},
				illustrations: {
					illustrations: 'イラスト',
					browse_all: 'すべての3Dイラストを閲覧',
				},
				mockups: {
					mockups: 'モックアップ',
					branding_kits: 'ブランディングモックアップキット',
					browse_all: 'すべてのリアルな3Dモックアップを閲覧',
				},
				designs: {
					designs: 'デザイン',
					browse_all: 'すべての3Dデザインを閲覧',
				},
			},
		},
		pack_details: {
			mockup_descriptions:
				'プロフェッショナルなブランディングモックアップテンプレートのライブラリで、即座にインスピレーションを得て、ブランドのデザインを展示しましょう。スタイリッシュな名刺、目を引くパッケージング、印象的なソーシャルメディアアセット、ウェブサイトモックアップまで、リアルな設定であなたのブランドアイデンティティを視覚化するために必要なものがすべて揃っています。',
			features: {
				premium_asset: 'すべてのプレミアムアセット',
				exclusive_materials: '限定素材とプリセット',
				exclusive_poses: '限定キャラクターポーズ',
				commercial_license: '商用ライセンス',
				export: '3Dファイルのエクスポート',
				edit: '色、素材、プリセットの編集',
				downloads: '高品質ダウンロード',
				elite_unlimited_images: '無制限の高品質画像',
				elite_video_per_month: '月30本の高品質動画',
			},
			get_all_access: 'すべてにアクセス',

			info: {
				info: '情報',
				number_of_items: 'アイテム数',
				file_type: 'ファイルタイプ',
				compatible: '互換性',
				release: 'リリース',
				author: '作者',
			},
			preview: {
				preview: 'プレビュー',
				default: {
					render: 'ブラウザのデフォルトエンジンでレンダリング',
					shadow: 'ハードシャドウ',
				},
				hq: {
					render: '当社のサーバーの最適化されたエンジンでレンダリング',
					shadow: 'レイトレーシング、リアルな影と照明',
				},
			},
			mockup_pack_description:
				'{pack_name}モックアップパックは、あなたのデザインをプロフェッショナルな方法で展示するのに最適な高品質の{pack_name}モックアップのコレクションです。<br> {pack_name}モックアップパックを使用すると、デザインを簡単にカスタマイズして提示できます。デザインをアップロードし、オブジェクトと背景を編集するだけで、リアルなモックアップを作成できます。<br> すべての{pack_name}モックアップは、様々なプラットフォームで使用できるように、PNG、GIF、MP4形式で利用可能です。',
			pack_description:
				'{pack_name}は、UX/UI、グラフィックデザイン、マーケティングに最適な3Dアイコンとアニメーションアイコンのセットです。<br> {pack_name}には、あらゆるニーズに対応する豊富な種類のアイコンが含まれており、統一された外観のために一貫した配色を維持しています。<br> すべての{pack_name}アイコンは、様々なプラットフォームで使用できるように、PNG、GLB、GIF、MP4形式で利用可能です。',
			character_pack_description:
				'{pack_name}キャラクターパックは、ウェブサイト、アニメーション、グラフィックデザイン、マーケティング、プレゼンテーションに最適な3Dキャラクターとアニメーションキャラクターのセットです。<br> このパックは各キャラクターのカスタマイズオプションを提供し、肌の色、髪の色、3Dポーズなどを変更できます。<br> すべての{pack_name}キャラクターは、様々なプラットフォームで使用できるように、PNG、GLB、GIF、MP4形式で利用可能です。',
			browse_more: 'さらに編集可能な3Dクリエイティブを閲覧',
			related_to_this_pack: 'このパックに関連',
		},
		item_details: {
			features: {
				edit: '色、素材、照明などを編集',
				edit_character: 'ポーズ、色、素材、照明などを編集',
				edit_mockup: '画像の置き換え、色、素材、照明などを編集',
				downloads: '高品質ダウンロード',
				export: '3Dファイルをエクスポート',
				ai: 'AIスタイル転送を試す',

				realistic: 'リアルな3Dモックアップ',
				editable: '編集可能なデザインと背景',
				easy: '使いやすいツール',
				high_quality: '高品質エクスポート',
				free: '商用利用無料',
				customize: 'カスタマイズ可能な形状、色、素材',
				high_resolution: '高解像度3Dデザイン',

				file_types: 'PNG、GLBファイルタイプ',
				free_license: '商用および個人プロジェクト用の無料ライセンス',
				ready_made: '既製でカスタマイズ可能なテンプレート',
				easy_switch: '様々なサイズに簡単に切り替え',
				file_types_2: 'PNGファイルタイプ',
				item_description:
					'{item_name}は、ウェブ、アプリ、UX/UI、グラフィックデザイン、マーケティングに最適なアイコンです。<br> {item_name}は完全にカスタマイズ可能で、色、素材、スタイルをデザインプロジェクトに合わせて変更できます。<br> {item_name}をPNG、GLB、GIF、MP4形式でダウンロードし、Figma、Blender、Adobeなどで使用できます。',
				character_description:
					'{item_name}は、ウェブサイト、アニメーション、グラフィックデザイン、マーケティング、プレゼンテーションに最適なキャラクターです。<br> {item_name}は完全にカスタマイズ可能で、肌の色、髪の色、3Dポーズなどを変更できます。<br> PNG、GLBファイル形式で利用可能です。',
				mockup_description:
					'この{item_name}モックアップは、ブランディング、ロゴ、ウェブサイトレイアウトなどを表示するのに最適です。<br> オブジェクト、背景を簡単にカスタマイズし、独自のデザイン要素を追加して、本当にユニークなものにすることができます。<br> {item_name}モックアップは、簡単な編集と様々なプラットフォームでの使用のために、PNG、GIF、MP4など複数のファイル形式で提供されています。',
			},

			open_in_mnm: 'Mix-n-matchで開く',
			edit_this_asset: 'このアセットを編集',

			more_from_this_pack: 'このパックからさらに',
			people_also_downloaded: '他の人もダウンロードしています',
			text_3d_graphic: '{item_name}3Dグラフィック',
			text_3d_illustration: '{item_name}3Dイラスト',

			more_from_this_bundle: 'このバンドルのテンプレート',
			related: '関連する{resouce_type}',
		},
		search: {
			explore: '探索',
			prefix_free: '無料',
			prefix_premium: 'プレミアム',
			prefix_free_and_premium: '無料＆プレミアム',
			icon_packs: 'アイコンパック',
			character_packs: 'キャラクターパック',
			mockup_packs: 'モックアップパック',
			asset_packs: 'アセットパック',
			icon: 'アイコン',
			icons: 'アイコン',
			character: 'キャラクター',
			characters: 'キャラクター',
			mockup: 'モックアップ',
			mockups: 'モックアップ',
			asset: 'アセット',
			assets: 'アセット',

			description: {
				suffix_animated: 'PNG、GLB、GIF、MP4ファイル形式で利用可能。',
				suffix_pack: 'ユーザーの注目を集めるためにアニメーション{search}{type}パックを試してみてください。',
				prefix_pack: 'カスタマイズしてダウンロード',
				suffix_icon_packs: 'パックを次のデザインプロジェクトに。',
				suffix_character_packs: 'パックをウェブ、アプリ、UX/UI、グラフィックデザイン、マーケティングに。',
				suffix_mockup_packs: 'パックをプレゼンテーションに。',
				suffix_asset_packs: 'パックを次のデザインプロジェクトに。',
				suffix_item: 'ユーザーの注目を集めるためにアニメーション{search}{type}を試してみてください。',
				prefix_item: 'カスタマイズしてダウンロード',
				suffix_icon_items: 'アイコン、イラスト、シンボル、ロゴを次のデザインプロジェクトに。',
				suffix_character_items: 'をウェブ、アプリ、UX/UI、グラフィックデザイン、マーケティングに。',
				suffix_mockup_items: 'を次のデザインプロジェクトに。',
				suffix_asset_items: 'を次のデザインプロジェクトに。',
			},

			elements: '要素',
			packs: 'パック',
			illustrations: 'イラスト',
			designs: 'デザイン',
			Elements: '要素',
			Packs: 'パック',
			Illustrations: 'イラスト',
			Mockups: 'モックアップ',
			Designs: 'デザイン',
			empty: '心配しないでください！他のアセットタイプを探索するか、',
			request_new: '新しいものをリクエスト',
			cant_find: '見つかりませんか',
			browse_relevants: '関連する検索をブラウズ',
		},
		my_projects: {
			title: 'プロジェクト',
			description: 'すべてのプロジェクトを一か所で簡単にカスタマイズおよび管理できます。',
			header: 'マイプロジェクト',
			search_placeholder: 'ファイルを検索',
			new_design: '新しいデザイン',
			default: 'デフォルト',
			advanced_editor: '高度なエディタ',
			all: 'アニメーション + 静止画',
			design: '静止画',
			video: 'アニメーション',
			edited: '編集済み',
			duplicate_loading: 'プロジェクトを複製中....',
			sort: {
				sort_by: '並び替え',
				recent: '最近',
				alphabetical: 'アルファベット順',
				oldest: '古い順',
			},

			menu: {
				open_in_advanced_editor: '高度なエディタで開く',
				duplicate: '複製',
				duplicate_submission: '提出用として複製',
				rename: '名前変更',
				delete: '削除',
			},

			empty_state: {
				title: 'プロジェクトを追加しましょう！',
				description: 'このページは現在空です。3Dアセットのカスタマイズを始める時です。',
			},
		},
		my_assets: {
			title: 'ダウンロード',
			description: 'ここですべてのダウンロードしたアセットを表示できます。いつでもアセットを再ダウンロードできます。',
			header: 'マイアセット',
			search_placeholder: 'ファイルを検索',
			sort: {
				sort_by: '並び替え',
				oldest: '古い順',
				newest: '新しい順',
				alphabet_az: 'アルファベット順（A-Z）',
			},
			type: {
				all: 'すべてのタイプ',
				default_render: 'デフォルトレンダリング',
				high_quality: '高品質',
				ai_generated: 'AI生成',
			},

			downloads: {
				downloads: 'ダウンロード',
				file: 'ファイル',
				download_status: 'ダウンロード状況',
				download_date: 'ダウンロード日',

				empty_state: {
					title: 'ダウンロードを追加しましょう！',
					description: 'このページは現在空です。クールなアセットのダウンロードを始める時です。',

					heading_1: '申し訳ありません、結果が見つかりませんでした',
					heading_2: '申し訳ありません、「{search}」に一致するものが見つかりませんでした',
					text: 'あなたのダウンロードページは少し寂しそうです。クールなアイテムのエクスポートを始める良い機会です。',
					text_search: '検索やフィルターを調整して、お探しのものを見つけてください。',
					button: 'さらに探索',
				},
			},
			purchases: {
				purchases: '購入',
				file: 'ファイル',
				purchase_date: '購入日',
				open_file: 'ファイルを開く',
				empty_state: {
					title: '購入履歴が空です。',
					text: 'このスペースをあなたのアイテムで埋めましょう。',
					heading_1: '申し訳ありません、結果が見つかりませんでした',
					heading_2: '申し訳ありません、「{search}」に一致するものが見つかりませんでした',
					text_search: '検索やフィルターを調整して、お探しのものを見つけてください。',
					button: 'さらに探索',
				},
				order_no: '注文番号',
			},
		},
		upgrade_plan: {
			discount_text: '{discount_program}をお楽しみください：プランが{discount}%オフ！',
			end_in: '期間限定オファーの終了まで',
			header: 'Pixcapで3Dの<br>すべての力を解放',
			is_pro: 'ご愛顧いただきありがとうございます！次回の更新まで、旧料金でサービスをお楽しみいただけます。',
			monthly: '月払い',
			quarterly: '3ヶ月',
			yearly: '年払い',
			save_up: '最大33%節約',
			per_month: '/月',
			recommended: 'おすすめ',
			current_short: '現在',
			current_plan: '現在のプラン',
			contact_sales: '営業に問い合わせ',
			get_plan: '{plan}を取得',
			bill_annually: '年間{amount}で請求',
			bill_quarterly: '3ヶ月間{amount}で請求',
			free: {
				free: '無料',
				text: 'テキスト',
				forever: '永久',
			},
			pro: {
				pro: 'プロ',
				text: 'よりプロフェッショナルなプロジェクトのためのプレミアム機能を解放。',
			},
			elite: {
				elite: 'エリート',
				text: '拡張機能を備えた大規模プロジェクト向け。',
			},
			enterprise: {
				enterprise: 'エンタープライズ',
				text: '高度なソリューションと重要プロジェクト向けのカスタマイズされたサポート。',
				custom: 'カスタム',
			},
			benefits: {
				limited_free_assets: '限定無料アセット',
				images_per_month: '月間{number}枚の高品質画像',
				videos_per_month: '月間{number}本の高品質動画',
				exclusive_character_poses: '限定キャラクターポーズ',
				exclusive_materials_presets: '限定素材とプリセット',
				commercial_license: '商用ライセンス',
				all_premiums: 'すべてのプレミアムアセット',
				export_3d_files: '3Dファイルのエクスポート',
				unlimited_images: '無制限の高品質画像',

				upto_10_projects: '最大10プロジェクト',
				monthly_credits_30: 'スーパーダウンロードとPixcap AIで月間30クレジット',
				commercial_license_with_watermark: '商用ライセンス（ウォーターマーク付き）',

				upto_50_projects: '最大50プロジェクト',
				monthly_credits_600: 'スーパーダウンロードとPixcap AIで月間600クレジット',
				super_download: '3倍速いスーパーダウンロード（画像）',

				unlimited_projects: '無制限プロジェクト',
				monthly_credits_2000: 'スーパーダウンロードとPixcap AIで月間2000クレジット',
				dedicated_support: '専任サポート',
				everything_in_pro: 'Proのすべての機能',
				modeling_services: '3Dモデリングサービス',
				unlimited_credits: '無制限クレジット',
				custom_animations: 'カスタムアニメーション',
				custom_mockups: 'カスタムモックアップ',
				everything_in_elite: 'Eliteのすべての機能',
			},
			vat_included: '付加価値税（VAT）込み',
			credit_cards: 'クレジットカードとデビットカードに対応',
			cancel_anytime: 'いつでもキャンセル可能',
			trusted_by: '信頼されている企業',
			show_full: '全て表示',
			hide: '隠す',
			limit_per_day: '1日{number}回',
			limit_per_month: '月間{number}回',
			unlimited: '無制限',
			limited: '限定',
			medium: '中',
			fast: '高速（3倍）',
			customization: {
				customization: 'カスタマイズ',
				all_premiums: 'すべてのプレミアム3Dアセット（アイコン、キャラクター、モックアップ）',
				all_character_poses: 'すべてのキャラクターポーズ',
				all_materials_presets: 'すべての素材とプリセット',
			},
			exports: {
				exports: 'エクスポート',
				tooltip:
					'画像/動画を標準または高品質でエクスポートできます：<br> &#x2022; 標準：デフォルトの品質レンダリング。<br> &#x2022; 高品質：より詳細でプロフェッショナルな画像/動画のための強化されたレイトレーシング、影、シャープネス。',
				standard: 'デフォルトの画像と動画',
				hq_images: '高品質（HQ）画像',
				hq_videos: '高品質（HQ）動画',
				rendering_speed: 'レンダリング速度（画像用）',
				resolution_4k_export: '4K解像度エクスポート',
				file_3d_export: '3Dファイルエクスポート（GLTF/GLB）',
			},
			features: {
				features: '機能',
				all_premiums: 'すべてのプレミアム3Dグラフィック（テンプレート、アイコン、シーン）',
				free_only: '無料のみ',
				graphics_upload: 'グラフィックのアップロード',
				fonts_upload: 'フォントのアップロード',
				custom_artboard_sizes: 'カスタムアートボードサイズ',
				resolution_4k_export: '4K解像度エクスポート',
				file_3d_export: '3Dファイルエクスポート（GLTF/GLB）',
			},
			credit_based_features: {
				credit_based_features: 'クレジットベースの機能',
				super_download: 'スーパーダウンロード',
				super_download_tooltip:
					'スーパーダウンロードは、詳細でプロフェッショナルな<br>画像/動画のための高品質な<br>レイトレーシング、影、シャープネスを提供します。<br>&#x2022; 画像1枚につき1クレジット<br>&#x2022; 動画0.1秒につき1クレジット',
				rendering_speed: 'レンダリング速度（画像用）',
				pixcap_ai: 'Pixcap AI',
				pixcap_ai_tooltip: 'AI生成1回につき1クレジット',
				custom_ai_prompt: 'カスタムAIプロンプト',
				private_ai_output: 'プライベートAI出力',
				background_remover: '背景削除',
				monthly_credits: '月間クレジット',
				super_and_ai: '（スーパーダウンロードとPixcap AI）',
				unlimited: '無制限',
				medium: '中',
				fast: '高速（3倍）',
			},
			ai_style_transfer: {
				ai_style_transfer: 'AIスタイル転送',
				tooltip: 'Pixcap AIを使用して、アイコンを瞬時にマット、光沢、ファブリック、ガラス、インフレーションなどの異なるスタイルに変換します。',
				ai_style_transfer_usage: 'AIスタイル転送の使用',
				custom_ai_prompt: 'カスタムAIプロンプト',
				bg_removal: '背景削除',
			},
			licensing: {
				licensing: 'ライセンス',
				personal_use: '個人使用',
				commercial_use: '商用利用',
				watermark: 'ウォーターマーク',
			},
			tailored_services: {
				tailored_services: 'カスタマイズサービス',
				modeling_services: '3Dモデリングサービス',
				custom_animations: 'カスタムアニメーション',
				custom_mockups: 'カスタムモックアップ',
				dedicated_support: '専任サポート',
			},
			support: 'サポート',
			faq: {
				pricing: '料金',
				faqs: 'よくある質問',
				what_is_hq_download: '高品質ダウンロードとは何ですか？',
				what_is_hq_download_answer: 'エクスポートされる画像と動画の品質が大幅に向上し、改善されたレイトレーシング、よりリアルな影と照明が特徴です。',
				what_is_super_download: 'スーパーダウンロードとは何ですか？',
				what_is_super_download_answer:
					'スーパー形式でエクスポートされる画像と動画の品質が大幅に向上し、改善されたレイトレーシング、よりリアルな影と照明が特徴です。',
				can_i_use_pixcap_for_free: 'Pixcapを無料で使用できますか？',
				can_i_use_pixcap_for_free_answer:
					'もちろんです！Pixcapは誰でも利用できる無料プランを提供しています。すべてのテンプレートとコンテンツを無料で閲覧できます。PROアセットやテンプレートが気に入った場合、AIゲームをレベルアップしたい場合、またはアニメーションモックアップツールを使用したい場合は、ニーズに応じてProまたはEliteプランにアップグレードできます。有料プランでは、スーパーダウンロード、4Kダウンロード、GLBファイルのダウンロード、すべてのコンテンツへのアクセス、アニメーション3Dモックアップの実験が可能です。',
				will_my_subscription_automatically_renew: '私の購読は自動的に更新されますか？',
				will_my_subscription_automatically_renew_answer:
					'はい、月次プランか年次プランかによって、手動でキャンセルするまで、それに応じて購読は更新されます。次の請求サイクルまでプランの特典にアクセスできます。',
				what_are_pixcap_credits: 'Pixcapクレジットとは何ですか？',
				what_are_pixcap_credits_answer: `Pixcapクレジットを使用すると、Pixcapの可能性を最大限に引き出すことができます！基本的に、スーパーダウンロード、Pixcap AI、スーパーダウンロードアニメーションエクスポートにはクレジットが必要で、各ツールは特定の量のクレジットを使用します。例えば、スーパーレンダリングを使用した1枚の静止画エクスポートは1クレジット分、スーパーレンダリングアニメーションの1秒のエクスポートは{amount}クレジット分です。Pixcapクレジットについての詳細は、Pixcapクレジットページでご確認いただけます。`,
				how_do_my_pixcap_credits_renew: '私のPixcapクレジットはどのように更新されますか？',
				how_do_my_pixcap_credits_renew_answer:
					'はい、各無料ユーザーは毎月30個の無料Pixcapクレジットを持っています。より多くのクレジットが必要だが購読にコミットしたくない場合は、トップアップバンドルの1つを購入できます。',
				do_free_users_also_have_pixcap_credits: '無料ユーザーもPixcapクレジットを持っていますか？',
				do_free_users_also_have_pixcap_credits_answer:
					'Pixcapクレジットは毎月1日にリセットされます。前月の未使用クレジットはすべて失われるので、すべて使い切るようにしてください！',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles: 'ProまたはEliteユーザーもトップアップバンドルから購入できますか？',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles_answer:
					'はい！購読クレジットをすべて使い切った場合、トップアップバンドルから購入できます - 月間制限はないので、必要な分だけ購入できます！',
				can_i_use_pixcap_for_commercial_purposes: 'Pixcapを商用目的で使用できますか？',
				can_i_use_pixcap_for_commercial_purposes_answer:
					'エクスポートされたすべてのコンテンツは商用利用可能ですが、無料ユーザーには帰属表示が必要です。PROおよびELITEユーザーは帰属表示を提供する必要はなく、Pixcapのすべてのコンテンツをプロジェクトで商用利用できます。',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription: '購読をキャンセルすると、ダウンロードへのアクセスを失いますか？',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription_answer: '購読に関係なく、ダウンロードは永久にあなたのアカウントに残ります。',
				what_is_your_refund_policy: '返金ポリシーはどのようになっていますか？',
				what_is_your_refund_policy_answer:
					'Pixcap購読の支払いは、EU（欧州連合）またはトルコにお住まいの場合を除き、返金不可です。これらの地域にお住まいの場合、購入から14日以内に購読をキャンセルし、<span class="text-weight-700">サービスを使用していない（つまり、Pixcapからリソースをダウンロードしていない）</span>場合にのみ返金の対象となる可能性があります。' +
					'<div class="mt-m">返金をリクエストするには、<span class="text-color-primary-5 text-underline">support@pixcap.com</span>までメールを送信し、お住まいの国を記載してください。</div>',
			},
			testimonials: {
				testimonials: 'お客様の声',
				header: '当社のお客様の声',
			},
			do_more: {
				do_more: 'より多くのことを',
				header: 'アップグレードして、強力な3D＆AIデザインツールの完全なスイートを探索',
				text: 'Pixcapは、カスタマイズ可能な3Dアイコン、グラフィック、イラストを提供するブラウザベースのプラットフォームです。多くの機能が無料で、3D編集のニーズをすべて満たすワンストップショップを提供します。アカウント作成は必須ではありませんが、クリエイティブな作品を保存できるため、いつでもアイコンを編集およびダウンロードできるようになるので推奨されます。',
				upgrade_now: '今すぐアップグレード',
			},
		},
		user_settings: {
			account_settings: {
				header: 'アカウント設定',
				email_address: 'メールアドレス',
				change_photo: '写真を変更',
				text: 'アカウントの詳細、プロフィールなどを表示および更新します。',
				profile_photo: 'プロフィール写真',
				account_settings: 'アカウント設定',
				display_name: '表示名',
				bio: '自己紹介',
				bio_placeholder: '自己紹介を入力してください',
				save_photo: '画像を保存',
			},
			invite_friends: {
				invite_friends: '友達を招待',
				header: '友達を招待して、即座に報酬を獲得',
				text: '招待リンクを友達と共有し、獲得した報酬を追跡します。',
				invite: '招待',
				by_link: 'リンクで',
				copy: 'リンクをコピー',
				by_email: 'メールで',
				enter_email: 'メールアドレスを入力',
				send: 'メールを送信',
				invitation_sent: '招待を送信しました！',
				how_it_works: '仕組み',
				note: 'Pixcap紹介プログラムは終了しました。以下の獲得報酬は、プログラム終了前に参加した方のみに関連しています。',
				earned_rewards: '獲得した報酬',
				referrals: '紹介',
				date: '日付',
				status: 'ステータス',
				earnings: '獲得額',
				no_rewards: '獲得報酬はありません。',
				tooltip: {
					pending: `{start_date}から{expired_date}までの間に<br>友達がサインアップするのを待っています。`,
					joined: '友達が少なくとも1つのデザインを<br>ダウンロードするのを待っています。',
				},
				claimed: '請求済み',
				pending: '保留中',
			},
			subscription: {
				text: '購読の表示、管理、またはキャンセルを行います。',
				billed_annually: '年間請求',
				per_month: '月額',
				per_day: '日額',
				free_text: 'このプランには限定されたアセットと機能が含まれています。',
				free_text_2: 'アップグレードして15,000以上のプレミアムアセットと高品質ダウンロードへの無制限アクセスを取得。',
				downloads: 'ダウンロード',
				downloads_limit: {
					default: 'デフォルト品質の画像+動画',
					hq_image: '高品質画像',
					hq_video: '高品質動画',
				},
				ai_style: 'AIスタイル転送',
				ai_limits: {
					ai_uses: 'AI使用回数',
				},
				topped_up: 'トップアップ済み',

				subscription: '購読',
				renew_plan: 'プラン更新',
				plan: 'プラン',
				on_free: '現在、無料アカウントです',
				sub_expired: '**購読期間が終了しました。',
				payment_unsucess: '**支払いが失敗しました。もう一度購読をお試しください。',
				payment_pending: '**支払いが保留中です。支払いを続行するためにご確認ください。',
				payment_invalid: '**支払いが無効です。支払い詳細を確認し更新してください。',
				plan_to_renew: '**更新するプランを選択してください。',
				check_payment: '支払いを確認',
				go_to_payment: '支払いに進む',
				choose_a_plan: 'プランを選択',
				complete_payment_in_your_plan: '{plan}を継続するために支払いを完了してください',
				cancel_plan: 'プランをキャンセル',
				change_plan: 'プランを変更',
				plan_end_on: 'プラン終了日',
				next_payment: '次回支払い',
				view_all_invoice: 'すべての請求書を表示',
				credits: 'クレジット',
				current_monthly_credits: '現在の月間購読クレジット残高',
				credit_note: 'これらのクレジットは毎月リセットされる購読の一部です。',
				topup_balance: 'トップアップクレジット残高',
				topup: 'クレジットをトップアップ',
				note: 'トップアップで獲得したクレジットです。これらのクレジットには<br />月間制限がなく、購読クレジットが利用可能な間は<br />消費されません。',
				per_year: '年間',
				for_3_months: '3ヶ月間',
				get_more_create_more: 'より多く取得し、より多く創造しよう！',
				save_more: '年間プランでさらに節約',
				upgrade_plan: 'プランをアップグレード',
				unlock_all: 'すべての機能をアンロック',
				topup_modal: {
					choose_a_bundle: 'バンドルを選択',
					credits: 'クレジット',
					text: '以下の<strong>3つのうち1つ</strong>に相当：',
					super_render_images: 'スーパーレンダリング画像',
					super_render_videos: 'スーパーレンダリング動画',
					images: '{credits}枚の画像',
					seconds: '{credits}秒',
					ai: 'AI生成',
					generations: '{credits}回の生成',
					amount: '金額',
					purchase_now: '今すぐ購入',
				},
				cancel_sub_modal: {
					cancel_sub: '購読をキャンセル',
					sub: '{plan}プランが<strong>{expireDay}</strong>に終了すると、すべてのプレミアム機能が失われます',
					sub_alt: 'キャンセルするとすぐにすべてのプレミアム機能が失われます。',
					let_us_know: '退会理由をお聞かせください',
					write_feedback: 'フィードバックを書いてください...',
					confirmed: 'キャンセルが確認されました！',
					update_text: '購読の更新には少し時間がかかる場合があります。',
					enjoy:
						'<strong>{expireDay}</strong>まではすべてのPixcap {plan}機能をお楽しみいただけます。その後、プレミアム機能へのアクセスはできなくなります。',
					change_your_mind: 'もし考えが変わった場合は、購読設定でプランの更新をご検討ください 😊！',
					sad: 'お別れするのは悲しいです！引き続き<strong>無料</strong>ユーザーとしてPixcapをお楽しみいただけます。',
					change_your_mind_2: 'もし考えが変わった場合は、購読設定で再度購読をご検討ください 😊！',
					keep: 'Pixcap {name}を継続',
					got_it: '了解しました',
					reason: {
						reason_1: '高すぎる',
						reason_2: 'より多くの機能が必要',
						reason_3: '他の類似のウェブサイトを見つけた',
						reason_4: 'あまり頻繁に使用しない',
						reason_5: '質の高いリソースが少ない',
						reason_6: 'Pixcap {plan}は価格に見合わないと思う',
						reason_7: '他の類似のウェブサイトを見つけた',
					},
				},
				plan_name: {
					pro_quarterly_plan: 'Proクォータリープラン',
					elite_quarterly_plan: 'Eliteクォータリープラン',
					free_plan: '無料プラン',
					pro_annual_plan: 'Pro年間プラン',
					elite_annual_plan: 'Elite年間プラン',
					pro_monthly_plan: 'Pro月間プラン',
					elite_monthly_plan: 'Elite月間プラン',
				},
			},
			promotion: {
				promotion: 'プロモーション',
				text: '紹介割引と週間無料特典を追跡。',
				only_one: '請求期間ごとに<span class="text-weight-600">1つ</span>の購読ベースの報酬のみ。以下からお気に入りを見つけてください。🌟',
				got_it: '了解しました',
				no_promotions: '利用可能なプロモーションはありません',
				watermark_promo: {
					title: '紹介無料特典',
					description: '無料アセットの無制限ウォーターマークなしダウンロード',
					valid_until: '{date}まで有効',
					no_expiration_date: '有効期限なし',
					unlimited_referrals: '無制限の紹介',
					learn_more: '詳細を見る',
					claim: '申請',
					claimed: '申請済み',
				},
			},
			change_password: {
				change_password: 'パスワード変更',
				current: '現在のパスワード',
				current_placeholder: '現在のパスワードを入力',
				new: '新しいパスワード',
				new_placeholder: '新しいパスワードを入力',
				confirm: 'パスワード確認',
				confirm_placeholder: '新しいパスワードを再入力',
				error_1: 'パスワードを空にすることはできません',
				error_2: '新しいパスワードを空にすることはできません',
				error_3: 'パスワード確認を空にすることはできません',
				error_4: 'パスワードが一致しません',
				error_5: 'パスワードが正しくありません',
			},
			email_notification: {
				text: 'ニュースレターとアカウントアクティビティアラートの設定を行います。',
				email_notification: 'メール通知',
				alerts_and_newsletter: 'アラート＆ニュースレター',
				downloads: 'ダウンロード',
				downloads_text: '高品質＆AI画像がダウンロード可能になった時に通知を受け取る',
				events: 'イベント',
				events_text: 'お気に入りのイベントパックが公開された時に通知を受け取る',
				newsletters: 'ニュースレター',
				newsletters_text: 'ニュースやデザインインスピレーションのマーケティングニュースレターを受け取る',
				account_activity: 'アカウントアクティビティ',
				email_when: '以下の場合にメールで通知',
				comments: '誰かが私のプロジェクトにコメントした時',
				mentions: '誰かが私に言及した時',
				collab_invites: 'プロジェクトへの協力依頼を受けた時',
				pending_actions: '保留中のアクション',
				conversation: 'プロジェクト内の会話',
			},
			save: '保存',
			save_changes: '変更を保存',
		},
		shared_project: {
			search_placeholder: '共有プロジェクトを検索...',
			title: '自分と共有',
		},
		contributor_profile: {
			pixcap: {
				display_name: 'Pixcapチーム',
				description:
					'UX/UI、テクノロジー、旅行、ビジネス、教育、食品、スポーツなど、15,000以上のカスタマイズ可能な3Dアセットにアクセスできます。色の変更、回転、ポーズ付け、エフェクトの追加を行って、アイコン、イラスト、キャラクターを本当に自分のものにすることができます。',
			},
		},
	},
	payments: {
		method: '支払方法',
		card_ewallets: 'カード / 電子ウォレット',
	},
	common_elements: {
		explore_item_type_banner: {
			icons: {
				title: '3Dアイコンとアニメーションアイコンをカスタマイズ',
				description:
					'3Dエディターを使用して、3Dアイコンの色、素材、アニメーションスタイルを調整してみてください。Figma、Sketch、Adobeなどで使用するための無料3DアイコンをPNG、GLB、GIF、MP4形式でダウンロードできます。',
				btn_text: '3Dアイコンを探索',
			},
			characters: {
				title: '3Dキャラクターに命を吹き込む',
				description: '3Dモデリングの専門知識がなくても、3Dキャラクターのポーズ付け、色の変更などができる唯一の3Dポーズツールを発見してください。',
				btn_text: '3Dキャラクターを探索',
			},
			mockups: {
				title: '数秒でアニメーション3Dモックアップを作成',
				description:
					'モックアップが完全にカスタマイズ可能になりました - あなたのラベルを任意のアニメーションモックアップにアップロードし、5分以内に4Kでダウンロードできます。',
				btn_text: 'モックアップを探索',
			},
		},
		status: {
			all: 'すべてのステータス',
			completed: '完了',
			processing: '処理中',
			failed: '失敗',
			cancelled: 'キャンセル済み',
			preparing: '準備中',
			removed: '削除済み',
		},
		packs_count: '{count}パック',
		items_count: '{count}アイテム',
		filter: {
			filters: 'フィルター',
			type: {
				type: 'タイプ',
				all: 'すべて',
				animated_static: 'アニメーション + 静止画',
				animated: 'アニメーション',
				static: '静止画',
			},
			price: {
				price: '価格',
				all: 'すべて',
				premium: 'プレミアム',
				free_paid: '無料 + 有料',
				free_premium: '無料 + プレミアム',
				free: '無料',
				paid: '有料',
			},
			size: {
				size: 'サイズ',
				all: 'すべてのサイズ',
			},
			pack_or_item: {
				view_as: '表示形式',
				items: 'アイテム',
				packs: 'パック',
			},
		},

		all_assets: 'すべてのアセット',
		icons: '3Dアイコン',
		characters: '3Dキャラクター',
		mockups: 'モックアップ',
		premium: 'プレミアム',
		asset_packs: '3Dアセットパック',
		icon_packs: '3Dアイコンパック',
		character_packs: '3Dキャラクターパック',
		mockup_pack: 'モックアップパック',

		edit: '編集',
		high_quality: '高品質',
		default: 'デフォルト',
		subscribe_and_edit: '購読して編集',
		get_elite_for_unlimited_access: '無制限アクセスにはEliteを取得',
		upgrade_to_elite: 'Eliteにアップグレード',
		unlock_all_access: 'すべてのアクセスをアンロック',
		explore_now: '今すぐ探索',
		by: '製作者：',
		new: '新着',
		copy_link: 'リンクをコピー',
		link_copied: 'リンクをコピーしました',

		or: 'または',
		show_all: 'すべて表示',
		view_all: 'すべて見る',
		view_more: 'もっと見る',
		free: '無料',
		purchased: '購入済み',
		animated: 'アニメーション',
		editable: '編集可能',
		tags: {
			all: 'すべて',
			trending: '人気',
		},
		coming_soon: '近日公開',
		get_started: '始める',

		more: 'もっと',
		edit_in_3d: '3Dで編集',
		view_details: '詳細を表示',
		read_more: 'もっと読む',
		download: 'ダウンロード',
		open: '開く',

		delete_modal: {
			title: '{entity}を削除',
			message: 'この{entity}を削除してもよろしいですか？',
			delete: '削除',
		},
		empty: {
			with_search: {
				message: '結果が見つかりません',
				sub_message: "'{search}' {type}をデザインしてほしいですか？",
			},
			request: 'リクエスト',
			message: '申し訳ありません！ここは現在空です。',
			sub_message: 'フィルターを調整するか、更新をお待ちください',
		},
		cancel: 'キャンセル',
		error_404: '申し訳ありませんが、お探しのページが見つかりませんでした。',
		error_500: '問題が発生しました。後でもう一度お試しください。',
		under_maintain: '現在、定期メンテナンスを実施中です。\nまもなくオンラインに戻ります。ご迷惑をおかけして申し訳ありません。',
		error_password_length: 'パスワードは8文字以上の文字または数字が必要です（スペースなし）。',
		error_password_not_matched: 'パスワードの確認が一致しません。',
		back_to_explore: '探索に戻る',
		retry: '再試行',
		render_type: {
			cloud: 'クラウドレンダリング',
			default: 'デフォルトレンダリング',
			ai: 'AIスタイル',
		},
		remove: '削除',
	},
	toast: {
		error: '問題が発生しました。更新して再試行してください。',
		success_delete_project: `プロジェクト{name}が正常に削除されました`,
	},
	tag_name: {
		essential: '必須',
		brand_kit: 'ブランドキット',
		device: 'デバイス',
		fintech: 'フィンテック',
		ecommerce: 'Eコマース',
		healthcare: 'ヘルスケア',
		business: 'ビジネス',
		casual: 'カジュアル',
		profession: '職業',
		laptop: 'ラップトップ',
		smartphone: 'スマートフォン',
		branding: 'ブランディング',
	},
	seo_meta: {
		customizable: 'カスタマイズ可能',
		free: '無料',
		animated: 'アニメーション',
		text_3d_animated_icons: '3Dアニメーションアイコン',
		text_3d_icons_illus: '3Dアイコンとイラスト',
		text_animated_icons: 'アニメーションアイコン',
		text_icons_logos_symbols: 'アイコン、ロゴ、シンボル',

		title_item_detail: `{keyWord}をPNG、GLB、GIF、MP4形式でダウンロード - Pixcap`,
		description_item_illustration_detail: `デザインプロジェクト用にカスタマイズ可能な{keyWord} ✓ 100以上の関連3Dイラスト ✓ 高解像度 ✓ 商用利用無料`,
		description_item_character_detail: `デザインプロジェクト用にカスタマイズ可能な{keyWord} ✓ 100以上の関連3Dキャラクター ✓ 高解像度 ✓ 商用利用無料`,
		description_item_icon_detail: `デザインプロジェクト用にカスタマイズ可能な{keyWord} ✓ 100以上の関連3Dアイコン ✓ 高解像度 ✓ 商用利用無料`,
		description_item_mockup_detail: `デザインプロジェクト用にカスタマイズ可能な{keyWord} ✓ 100以上の関連3Dモックアップ ✓ 高解像度 ✓ 商用利用無料`,
		description_item_design_detail: `デザインプロジェクト用にカスタマイズ可能な{keyWord} ✓ 100以上の関連3Dデザイン ✓ 高解像度 ✓ 商用利用無料`,

		title_brandkit_detail: `ビジネス用ブランディングモックアップテンプレート - Pixcap`,
		description_brandkit_detail: `無料およびプレミアムのブランディングモックアップテンプレートで、オンラインで素晴らしいブランドアイデンティティを作成。ロゴ、文房具、デバイス、オフィス用品などにデザインを視覚化。`,
		title_pack_detail: `{packKeyWord}アイコンパック | {packSize}の3Dアイコンとイラストをダウンロード`,
		description_pack_detail: `デザインプロジェクト用にカスタマイズ可能な{packKeyWord} 3Dアイコンパック ✓ 100以上の関連アイコンパック ✓ 高解像度 ✓ 商用利用無料`,

		title_library_icons: '{currentPlanQuery}{currentTypeQuery}{currentTagQuery}3Dアイコンパック - PNG、GLB、GIF、MP4形式で無料ダウンロード',
		description_library_icons: `グラフィックデザイン、UX/UI、プレゼンテーションなどに使用できる{currentPlanQuery}{currentTypeQuery}{currentTagQuery}3Dアイコンパックを見つける ✓ カスタマイズ、ダウンロード、共有可能 ✓ 高解像度 ✓ 商用利用無料`,

		title_library_characters: `{currentPlanQuery}{currentTypeQuery}{currentTagQuery}3Dキャラクター - PNG、GLB、GIF、MP4形式で無料ダウンロード`,
		description_library_characters: `グラフィックデザイン、UX/UI、プレゼンテーションなどに使用できる{currentPlanQuery}{currentTypeQuery}{currentTagQuery}3Dキャラクターを見つける ✓ カスタマイズ、ダウンロード、共有可能 ✓ 高解像度 ✓ 商用利用無料`,

		title_library_illusions: `{currentPlanQuery}{currentTagQuery}3D{currentTypeQuery}イラスト - PNG、GLB、GIF、MP4形式で無料ダウンロード`,
		description_library_illusions: `グラフィックデザイン、UX/UI、プレゼンテーションなどに使用できる{currentPlanQuery}{currentTagQuery}3D{currentTypeQuery}イラストを見つける ✓ カスタマイズ、ダウンロード、共有可能 ✓ 高解像度 ✓ 商用利用無料`,

		title_library_mockups: `{currentPlanQuery}{currentTypeQuery}{currentTagQuery}3Dモックアップ - PNG、GLB、GIF、MP4形式で無料ダウンロード`,
		description_library_mockups: `UX/UI、ポートフォリオ、プレゼンテーションなどに使用できる{currentPlanQuery}{currentTypeQuery}{currentTagQuery}3Dモックアップを見つける ✓ カスタマイズ、ダウンロード、共有可能 ✓ 高解像度 ✓ 商用利用無料`,
		title_library_mockups_default: `数秒で3Dモックアップとアニメーション3Dモックアップを作成`,
		description_library_mockups_default: `製品、ポスター、名刺、本の表紙などのリアルな3Dモックアップ画像と動画を生成。オンラインで使用できる無料モックアップジェネレーター。`,

		title_library_designs: '3Dアイコン、イラスト、モックアップ - PNG、GLB、GIF、MP4形式で無料ダウンロード',
		description_library_designs:
			'グラフィックデザイン、プレゼンテーション、ウェブプロジェクト用の10,000以上の3D要素を閲覧してダウンロード ✓ 高解像度 ✓ 商用利用無料',

		title_search_prefix: ' - PNG、GLB、GIF、MP4形式で無料ダウンロード',
		title_search_pack_list: `{totalItems}の{currentPlanQuery}{searchWord}{currentTypeQuery}3Dアイコンパック - PNG、GLB、GIF、MP4形式で無料ダウンロード`,
		description_search_pack_list: `{currentPlanQuery}{searchWord}3D{currentTypeQuery}アイコンパックをカスタマイズ＆ダウンロード ✓ 高解像度 ✓ 商用利用無料 ✓ 毎日新しいパックを追加`,
		title_search_icon_list: `{totalItems}の{currentPlanQuery}{searchWord}{currentTypeQuery} - PNG、GLB、GIF、MP4形式で無料ダウンロード`,
		description_search_icon_list: `{currentPlanQuery}{searchWord}3D{currentTypeQuery}をカスタマイズ＆ダウンロード ✓ 高解像度 ✓ 商用利用無料 ✓ 毎日新しいパックを追加`,
		title_search_scene_list: `{totalItems}の{currentPlanQuery}{searchWord}{currentTypeQuery}3Dイラスト - PNG、GLB、GIF、MP4形式で無料ダウンロード`,
		description_search_scene_list: `{currentPlanQuery}{searchWord}3D{currentTypeQuery}イラストをカスタマイズ＆ダウンロード ✓ 高解像度 ✓ 商用利用無料 ✓ 毎日新しいパックを追加`,
		title_search_mockup_list: `{totalItems}の{searchWord}3Dモックアップ - PNG、GLB、GIF、MP4形式で無料ダウンロード`,
		description_search_mockup_list: `{searchWord}3Dモックアップをカスタマイズ＆ダウンロード ✓ 高解像度 ✓ 商用利用無料 ✓ 毎日新しい3Dモックアップを追加`,
		title_search_template_list: `{totalItems}の{searchWord}3Dデザイン - PNG、GLB、GIF、MP4形式で無料ダウンロード`,
		description_search_template_list: `{searchWord}3Dデザインをカスタマイズ＆ダウンロード ✓ 高解像度 ✓ 商用利用無料 ✓ 毎日新しい3Dデザインを追加`,
		contributor_page: {
			title: '{name}の3Dデザイナーポートフォリオ | Pixcap',
			description: '{name}による高品質3Dデザインリソースを閲覧してダウンロード。Pixcapのデザイナーコミュニティとさらに美しい3Dアセットを今すぐ探索。',
		},
	},
};

export default jp;
